import React from "react";
import Input from "../input";
import Button from "../button";
import Modal from "../modal";
import PropTypes from "prop-types";
import styled from "styled-components";

const Form = styled.form`
    > button {
        margin-top: 32px;
    }
    > div {
        margin-bottom: 24px;
    }
`;

export default function AdminFormModal(props) {
    const [name, setName] = React.useState(props.name);
    const [nameError, setNameError] = React.useState("");

    async function onSubmit(event) {
        event.preventDefault();
        let shouldSubmit = true;

        if (!name.trim()) {
            shouldSubmit = false;
            setNameError("Full name must be filled");
        } else if (nameError.trim()) {
            setNameError("");
        }

        if (shouldSubmit && props.onSubmit) {
            props.onSubmit({ name });
        }
    }

    return (
        <Modal
            isActive={props.isActive}
            onCancel={props.onClose}
            title="Edit Administrator Settings"
        >
            <Form onSubmit={onSubmit} autoComplete="on">
                <Input
                    label="Full Name"
                    value={name}
                    onChange={setName}
                    error={nameError}
                    autoComplete="name"
                    required
                />
                <Button type="submit" fullWidth loading={props.loading}>
                    Save Changes
                </Button>
            </Form>
        </Modal>
    );
}

AdminFormModal.propTypes = {
    isActive: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    name: PropTypes.string,
}
