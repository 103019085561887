import { getStorageData, removeStorageData, saveStorageData, StorageKeys } from "../../lib/storageManager";
import React from "react";
import PropTypes from "prop-types";
import Loader from "../loader";

const defaultContextValue = {
    user:         null,
    onUserChange: (user) => {
        throw new Error(`Default function should not be used: ${user.displayName}`);
    },
    onLogout: () => {
        throw new Error(`Default function should not be used for logout`);
    },
}

export const authContext = React.createContext(defaultContextValue);

export function logout() {
    removeStorageData(StorageKeys.User);
    removeStorageData(StorageKeys.AuthToken);
    if (window.location.pathname !== "/") {
        window.location.reload();
    }
}

export default function AuthWrapper(props) {
    const [user, setUser] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        initApp()
    }, []);

    function initApp() {
        const userStored = getStorageData(StorageKeys.User);
        if (userStored) {
            setUser(userStored);
        }
        setLoading(false);
    }

    function onUserChange(val) {
        saveStorageData(StorageKeys.User, val);
        setUser(val);
    }

    async function onLogout() {
        removeStorageData(StorageKeys.User);
        removeStorageData(StorageKeys.AuthToken);
        setUser(null);
    }

    if (loading) {
        return <Loader />
    }

    return (
        <authContext.Provider
            value={{
                user,
                onUserChange,
                onLogout,
            }}
        >
            {props.children}
        </authContext.Provider>
    )
}

AuthWrapper.propTyppes = {
    children: PropTypes.element
}
