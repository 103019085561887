import React from "react";
import Input from "../input";
import Button from "../button";
import Modal from "../modal";
import PropTypes from "prop-types";
import styled from "styled-components";

const Form = styled.form`
    > button {
        margin-top: 32px;
    }
    > div {
        margin-bottom: 24px;
    }
`;

export default function AdminPasswordModal(props) {
    const [oldPassword, setOldPassword] = React.useState("");
    const [oldPasswordError, setOldPasswordError] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [passwordError, setPasswordError] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [confirmPasswordError, setConfirmPasswordError] = React.useState("");

    async function onSubmit(event) {
        event.preventDefault();
        let shouldSubmit = true;

        if (!oldPassword.trim()) {
            shouldSubmit = false;
            setOldPasswordError("Full name must be filled");
        } else if (oldPassword.trim()) {
            setOldPasswordError("");
        }
        if (!password.trim()) {
            shouldSubmit = false;
            setPasswordError("Password must be filled");
        } else if (password.trim().length < 6) {
            shouldSubmit = false;
            setPasswordError("Password must be at least six characters");
        } else if (passwordError.trim()) {
            setPasswordError("");
        }
        if (!confirmPassword.trim()) {
            shouldSubmit = false;
            setConfirmPasswordError("Confirm Password must be filled");
        } else if (confirmPassword !== password) {
            shouldSubmit = false;
            setConfirmPasswordError("Confirm Password must be equal to password");
        } else if (confirmPasswordError.trim() && confirmPassword === password) {
            setConfirmPasswordError("");
        }

        if (shouldSubmit && props.onSubmit) {
            props.onSubmit({
                oldPassword,
                password,
            });
        }
    }

    return (
        <Modal
            isActive={props.isActive}
            onCancel={props.onClose}
            title="Edit Administrator Settings"
        >
            <Form onSubmit={onSubmit} autoComplete="on">
                <Input
                    label="Old Password"
                    value={oldPassword}
                    onChange={setOldPassword}
                    type="password"
                    error={oldPasswordError}
                    autoComplete="current-password"
                    required
                />
                <Input
                    label="New Password"
                    value={password}
                    onChange={setPassword}
                    type="password"
                    error={passwordError}
                    autoComplete="current-password"
                    required
                />
                <Input
                    label="Confirm New Password"
                    value={confirmPassword}
                    onChange={setConfirmPassword}
                    type="password"
                    error={confirmPasswordError}
                    autoComplete="current-password"
                    required
                />
                <Button type="submit" fullWidth loading={props.loading}>
                    Save Changes
                </Button>
            </Form>
        </Modal>
    );
}

AdminPasswordModal.propTypes = {
    isActive: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}
