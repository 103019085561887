import React from "react";
import PageTitle from "../../components/pageTitle";
import Button from "../../components/button";
import TextContent from "../../components/textContent";
import Colour from "../../lib/colour";
import Tab from "../../components/tab";
import styled from "styled-components";
import PlaylistImage from "../../components/playlistImage";
import NewOrEditPlaylistModal from "../../components/newOrEditPlaylistModal";
import NewSongModal from "../../components/newSongModal";
import {gql, useMutation, useQuery} from "@apollo/client";
import {GET_SINGLE_PLAYLIST_WITH_SONGS} from "../../lib/graphQl/query";
import Loader from "../../components/loader";
import {DateTime} from "luxon";
import SongTable from "../../components/songTable";
import {showErrorNotification, showSuccessNotification} from "../../lib/notificationManager";
import {DELETE_SONG, EDIT_PLAYLIST, FEATURE_SONG_TOGGLE} from "../../lib/graphQl/mutation";
import {createSong, updatePlaylistBanner} from "../../lib/restService";
import CurrentSongPlaying from "../../components/currentlyPlaying";

const TitleWrapper = styled.div`
    display: flex;
    margin-top: 16px;
`;

const Details = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    margin-left: 16px;
    padding-bottom: 8px;
    
    > span:last-child {
        margin-top: auto;
    }
`;

const OverInfo = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StatWrapper = styled.div`
    background-color: ${Colour.Primary};
    border-radius:5px;
    padding: 12px 64px;
    display: inline-block;
    margin: 0 32px 32px 0;
`;
const GameStatWrapper = styled.div`
    background-color: ${Colour.Black};
    border-radius:5px;
    padding: 12px 64px;
    display: inline-block;
    margin: 0 32px 32px 0;
`;

const ButtonWrapper = styled.div`
    margin-bottom: 32px;
    margin-top: 64px;
`;

const EditIcon = styled.span`
    font-size: 16px;
    margin-left: 16px;
    color: ${Colour.BlackText};
    cursor: pointer;
`;

const TabItems = [
    { name: "All Time", key: null },
    { name: "Today", key: "TODAY" },
    { name: "This Week", key: "THISWEEK" },
    { name: "This Month", key: "THISMONTH" },
];

const pageLimit = 20;
export default function ViewPlaylist(props) {
    const [activeTab, setActiveTab] = React.useState(null);
    const [isEdit, setIsEdit] = React.useState(false);
    const [isModalActive, setModalActive] = React.useState(false);
    const [isNewSongModalActive, setNewSongModalActive] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const [playlistData, setPlaylistData] = React.useState(null);
    const [page, setPage] = React.useState(1);
    const [offset, setOffset] = React.useState(0);
    const [saving, setSaving] = React.useState(false);
    const [isProcessing, setProcessing] = React.useState(false);
    const [playingSongId, setPlayingSongId] = React.useState(null);

    if (!props.match?.params?.playlistId) {
        props.history.goBack();
    }

    const { loading } = useQuery(GET_SINGLE_PLAYLIST_WITH_SONGS, {
        variables: {
            playlistId: props.match?.params?.playlistId,
            offset,
            limit: pageLimit,
            filterBy: activeTab,
        },
        onCompleted: (data) => {
            setPlaylistData({ ...data });
            setLoading(false);
        },
    });
    const [deleteSong] = useMutation(DELETE_SONG);
    const [toggleSong] = useMutation(FEATURE_SONG_TOGGLE, {
        update: (cache, { data: { adminToggleTrackRadioFeature } }) => {
            cache.modify({
                fields: {
                    adminToggleTrackRadioFeature(existingData) {
                        const newToggleRef = cache.writeFragment({
                            data: adminToggleTrackRadioFeature,
                            fragment: gql`
                                fragment newToggleBoolean on adminToggle {
                                    id
                                    featuredOnRadio
                                }
                            `
                        });
                        return { ...existingData, data:newToggleRef }
                    }
                }
            })
        }
    });
    const [editPlaylist] = useMutation(EDIT_PLAYLIST, {
        update: (cache, { data: { adminEditPlaylist } }) => {
            cache.modify({
                fields: {
                    adminSearchSongsByPlaylist(existingListPlaylist) {
                        const updatedPlaylistRef = cache.writeFragment({
                            data: adminEditPlaylist.data,
                            fragment: gql`
                                fragment UpdatedPlaylist on Playlist {
                                    id
                                    title
                                    totalTracks
                                    totalPlays
                                    imageUrl
                                    createdAt
                                }
                            `
                        });

                        return {
                            ...existingListPlaylist,
                            playlist: updatedPlaylistRef,
                        }
                    }
                }
            });
        },
        onCompleted: (data) => setPlaylistData(prevData => ({
            ...prevData,
            adminSearchSongsByPlaylist: {
                ...prevData.adminSearchSongsByPlaylist,
                playlist: data.adminEditPlaylist.data,
            },
        })),
    });

    function onIsEdit() {
        setIsEdit(!isEdit);
    }

    function toggleEditPlaylistModal() {
        setModalActive(!isModalActive);
    }

    async function onEditPlaylist(editPlaylistData) {
        try {
            setProcessing(true);
            let response = {
                data: {
                    link: editPlaylistData.image,
                    status: "success",
                }
            };

            if (editPlaylistData.file) {
                const formData = new FormData();
                formData.append("playlistId", editPlaylistData.id);
                formData.append("intention", "playlist");
                formData.append("file", editPlaylistData.file);

                response = await updatePlaylistBanner(formData);
            }

            if (response.data?.status === "success") {
                const resp = await editPlaylist({
                    variables: {
                        id: editPlaylistData.id,
                        title: editPlaylistData.title,
                        description: `${editPlaylistData.title}-description`,
                        imageUrl: response.data.link,
                    }
                })

                if (resp?.data?.adminEditPlaylist?.status === "success") {
                    showSuccessNotification(resp.data.adminEditPlaylist.message);
                    toggleEditPlaylistModal();
                }

                if (resp?.data?.adminEditPlaylist?.status === "failed") {
                    showErrorNotification(resp.data.adminEditPlaylist.message);
                }
            }

            if (response.data?.status === "failed") {
                showErrorNotification(response.data.message);
            }
        } catch (e) {
            showErrorNotification("There is an error updating playlist");
        } finally {
            setProcessing(false);
        }
    }

    function toggleNewSongModal() {
        setNewSongModalActive(!isNewSongModalActive);
    }

    async function onNewSong(newSongData) {
        setSaving(true);
        try {
            const formData = new FormData();
            formData.append("playlistId", props.match?.params?.playlistId);
            formData.append("intention", "song");
            formData.append("songTitle", newSongData.title);
            formData.append("artistName", newSongData.artist);
            formData.append("albumName", newSongData.album);
            formData.append("file", newSongData.file);

            const response = await createSong(formData);

            if (response.data.status === "success") {
                toggleNewSongModal();
                showSuccessNotification("Song Added Successfully");
            }

            if (response.data.status === "failed") {
                showErrorNotification("Error Adding Song");
            }
        } catch (e) {
            showErrorNotification("There is an adding new song");
        } finally {
            setSaving(false);
        }
    }
    async function handleCheckChange(record,e){
        const resp = await toggleSong({
            variables: { id: record.id }
        });
        if (resp?.data?.adminToggleTrackRadioFeature?.status === "success") {
            showSuccessNotification(resp.data.adminToggleTrackRadioFeature.message);
        }

        if (resp?.data?.adminToggleTrackRadioFeature?.status === "failed") {
            showErrorNotification(resp.data.adminToggleTrackRadioFeature.message);
        }
    }
    function onDeleteSong(songId) {
        // TODO: update song data after delete
        return async () => {
            const resp = await deleteSong({
                variables: { id: songId }
            });
            if (resp?.data?.adminDeleteSong?.status === "success") {
                showSuccessNotification(resp.data.adminDeleteSong.message);
            }

            if (resp?.data?.adminDeleteSong?.status === "failed") {
                showErrorNotification(resp.data.adminDeleteSong.message);
            }
        }
    }

    if (isLoading) {
        return <Loader />;
    }

    if (!playlistData?.adminSearchSongsByPlaylist?.playlist) {
        props.history.goBack();
    }

    function onPrevPage() {
        setOffset((page - 2) * pageLimit);
        setPage(prevPage => prevPage - 1);
    }

    function onNextPage() {
        setOffset(page * pageLimit);
        setPage(prevPage => prevPage + 1);
    }

    return (
        <div>
            <PageTitle
                replaceComponent={(
                    <TitleWrapper>
                        <PlaylistImage
                            image={playlistData?.adminSearchSongsByPlaylist?.playlist.imageUrl}
                            isEdit={isEdit}
                            stopClickable={!isModalActive}
                        />
                        <Details>
                            <div>
                                <TextContent fontSize={24} fontWeight="700" display="block">
                                    {playlistData?.adminSearchSongsByPlaylist?.playlist.title}
                                    {isEdit && (
                                        <EditIcon onClick={toggleEditPlaylistModal}>
                                            <i className="fas fa-edit" />
                                        </EditIcon>
                                    )}
                                </TextContent>
                                <TextContent fontSize={14} colour={Colour.TextGrey}>
                                    {`${playlistData?.adminSearchSongsByPlaylist?.playlist.id}`}
                                </TextContent>
                                <div>
                                    <TextContent fontSize={14} colour={Colour.TextGrey}>
                                        {`${playlistData?.adminSearchSongsByPlaylist?.playlist.totalTracks} Songs`}
                                    </TextContent>
                                </div>
                            </div>
                            <TextContent fontSize={14} colour={Colour.TextGrey}>
                                {`Created ${DateTime.fromISO(playlistData?.adminSearchSongsByPlaylist?.playlist.createdAt).toFormat("dd-MM-y")}`}
                            </TextContent>
                        </Details>
                    </TitleWrapper>
                )}
                rightComponent={(
                    <Button onClick={onIsEdit} variant={isEdit ? "subdue" : "primary"}>
                        <TextContent fontSize={14} fontWeight="600" colour={Colour.White}>
                            {isEdit ? "Save Changes" : "Edit Playlist"}
                        </TextContent>
                    </Button>
                )}
                goBackText="Back to All Playlists"
                canGoBack
            />
            {isEdit
                ? (
                    <ButtonWrapper>
                        <Button shape="rectangle" fullWidth onClick={toggleNewSongModal}>
                            <TextContent fontSize={18} colour={Colour.White}>Upload Song</TextContent>
                        </Button>
                    </ButtonWrapper>
                )
                : (
                    <>
                        <Tab activeTab={activeTab} items={TabItems} onTabSelect={setActiveTab} />
                        <OverInfo>
                            <div>
                                <StatWrapper>
                                    <TextContent fontSize={36} colour={Colour.White} fontWeight="700" display="block" textAlign="center">
                                        {playlistData?.adminSearchSongsByPlaylist?.playlist.totalPlays}
                                    </TextContent>
                                    <TextContent fontSize={14} colour={Colour.White} textAlign="center">Times Played</TextContent>
                                </StatWrapper>
                                <GameStatWrapper>
                                    <TextContent fontSize={36} colour={Colour.White} fontWeight="700" display="block" textAlign="center">
                                        {playlistData?.adminSearchSongsByPlaylist?.playlist.totalPlays}
                                    </TextContent>
                                    <TextContent fontSize={14} colour={Colour.White} textAlign="center">Active Games</TextContent>
                                </GameStatWrapper>
                            </div>
                            <div>
                                <CurrentSongPlaying playlistIdParam={props.match?.params?.playlistId} playlistName={playlistData?.adminSearchSongsByPlaylist?.playlist.title} />
                            </div>
                        </OverInfo>
                    </>
                )
            }
            <SongTable
                data={playlistData?.adminSearchSongsByPlaylist?.tracks || []}
                total={playlistData?.adminSearchSongsByPlaylist?.totalCount || 1}
                onNextPage={onNextPage}
                onPrevPage={onPrevPage}
                onDelete={onDeleteSong}
                loading={loading}
                page={page}
                limit={pageLimit}
                hideTableTools={isEdit}
                hideSearch
                playingSongId={playingSongId}
                setPlayingSongId={setPlayingSongId}
                featureIcon
                handleCheckChange={handleCheckChange}
            />
            <NewOrEditPlaylistModal
                isEdit
                isActive={isModalActive}
                editingPlaylist={playlistData?.adminSearchSongsByPlaylist?.playlist}
                id={playlistData?.adminSearchSongsByPlaylist?.playlist?.id}
                name={playlistData?.adminSearchSongsByPlaylist?.playlist?.title}
                image={playlistData?.adminSearchSongsByPlaylist?.playlist?.imageUrl}
                onClose={toggleEditPlaylistModal}
                onSubmit={onEditPlaylist}
                isProcessing={isProcessing}
            />
            <NewSongModal
                isActive={isNewSongModalActive}
                onClose={toggleNewSongModal}
                onSubmit={onNewSong}
                isProcessing={saving}
            />
        </div>
    )
}
