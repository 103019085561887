import { notification } from "antd";
import styled from "styled-components";
import React from "react";
import Colour from "./colour";

const Icon = styled.span`
    font-size: 16px;
    color: ${props => props.color};
`;

const Button = styled.button`
    padding: 6px 8px;
    font-size: 14px;
    font-weight: 500;
    border: 0px;
    outline: none;
    background-color: transparent;
    color: ${props => props.color};
    cursor: pointer;
`;

export function showSuccessNotification(message, title) {
    const key = `success${Date.now()}`;
    const closeNotification = () => notification.close(key);
    const NotificationButton = (
        <Button color={Colour.NotificationSuccess} onClick={closeNotification}>Dismiss</Button>
    );

    notification.success({
        message:     title || "Success",
        description: message,
        icon: <Icon color={Colour.NotificationSuccess}><i className="fas fa-check-circle" /></Icon>,
        style: { backgroundColor: Colour.NotificationSuccessFade },
        btn: NotificationButton,
        key,
    });
}

export function showErrorNotification(message, title) {
    const key = `error${Date.now()}`;
    const closeNotification = () => notification.close(key);
    const NotificationButton = (
        <Button color={Colour.Rose} onClick={closeNotification}>Dismiss</Button>
    );

    notification.error({
        message:     title || "Error",
        description: message,
        icon: <Icon color={Colour.NotificationError}><i className="fas fa-check-circle" /></Icon>,
        style: { backgroundColor: Colour.NotificationErrorFade },
        btn: NotificationButton,
        key,
    });
}
