import React from "react";
import styled from "styled-components";
import Colour from "../../lib/colour";
import PropTypes from "prop-types";

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const ImageBlock = styled.div`
    position: relative;
    height: 112px;
    width: 148px;
    border-radius: 17px;
    margin-bottom: 8px;
    cursor: ${props => props.onClick ? "pointer" : "default"};
`;

const Image = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 17px;
    object-fit: cover;
`;

const HoveringImage = styled.div`
    position: absolute;
    top: 0px;
    background-color: rgba(196, 196, 196, 0.78);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 17px;
    
    
    > span {
        font-size: 40px;
        color: ${Colour.BlackText};
    }
`;

export default function PlaylistImage(props) {
    const [image, setImage] = React.useState(props.image || "");
    const fileInputRef = React.createRef();

    React.useEffect(() => {
        if (props.image) {
            setImage(props.image);
        }
    }, [props.image]);

    function onFileSelect(event) {
        if (props.onFileChange && event?.currentTarget?.files.length) {
            props.onFileChange(event.currentTarget.files[0]);
        }
    }

    function onInitiateFileSelect() {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    return (
        <ImageWrapper>
            <ImageBlock
                onClick={props.isEdit && !props.stopClickable ? onInitiateFileSelect : undefined}
                isEdit={props.isEdit}
            >
                {props.image && <Image src={image} alt="playlist-image" />}
                {props.isEdit && (
                    <HoveringImage>
                        <span><i className="fas fa-image" /></span>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={onFileSelect}
                        />
                    </HoveringImage>
                )}
            </ImageBlock>
        </ImageWrapper>
    )
}

PlaylistImage.propTypes = {
    image: PropTypes.string,
    isEdit: PropTypes.bool,
    stopClickable: PropTypes.bool,
    onFileChange: PropTypes.func,
}

PlaylistImage.defaultProps = {
    isEdit: false,
    stopClickable: false,
}
