import {DateTime} from "luxon";

const sortTrue = 1;
const sortFalse = -1;
const sortNeutral = 0;

export function dateComparator(a, b) {
    const aTime = DateTime.fromISO(a);
    const bTime = DateTime.fromISO(b);
    if (aTime < bTime) {
        return sortTrue;
    }

    if (aTime > bTime) {
        return sortFalse;
    }

    return sortNeutral;
}
