import { showErrorNotification } from "./notificationManager";

export const StorageKeys = {
    User: "muzingo_manager_user",
    AuthToken: "muzingo_auth_token",
}

export function getStorageData(key) {
    try {
        const rawUserData = localStorage.getItem(key);
        if (!rawUserData) {
            return null;
        }

        return JSON.parse(rawUserData);
    } catch (e) {
        showErrorNotification("Storage operation error: GET");
        return null;
    }
}

export function saveStorageData(key, value) {
    try {
        if (!value || !key) {
            return;
        }

        const stringifyValue = JSON.stringify(value);
        localStorage.setItem(key, stringifyValue);
    } catch (e) {
        showErrorNotification("Storage operation error: POST");
        return null;
    }
}

export function removeStorageData(key) {
    try {
        if (!key) {
            return;
        }

        localStorage.removeItem(key);
    } catch (e) {
        showErrorNotification("Storage operation error: DELETE");
        return null;
    }
}
