import { gql } from '@apollo/client';

export const GET_ADMINS = gql`
  query adminListStaff(
    $limit: Int!
    $offset: Int!
    $nameLike: String
    $sortType: MuzingoArraySortOption
    $sortOrder: MuzingoArraySortOption
  ) {
    adminListStaff(limit: $limit, offset: $offset, nameLike: $nameLike, sortType: $sortType, sortOrder: $sortOrder) {
      totalCount
      data {
        id
        fullname
        email
        dateRegistered
        accountActive
      }
    }
  }
`;

export const GET_USERS = gql`
  query adminListUsers(
    $limit: Int!
    $offset: Int!
    $nameLike: String
    $sortType: MuzingoArraySortOption
    $sortOrder: MuzingoArraySortOption
  ) {
    adminListUsers(limit: $limit, offset: $offset, nameLike: $nameLike, sortType: $sortType, sortOrder: $sortOrder) {
      totalCount
      data {
        id
        fullname
        accountActive
        totalGamesPlayed
        email
        dateRegistered
        username
        userExperience
        activePlan
        userBundleType
      }
    }
  }
`;

export const GET_PLAYLIST = gql`
  query listPlaylist(
    $limit: Int!
    $offset: Int!
    $filterBy: TimePlayedEnum
    $nameLike: String
    $sortType: MuzingoArraySortOption
    $sortOrder: MuzingoArraySortOption
  ) {
    listPlaylist(
      limit: $limit
      offset: $offset
      filterBy: $filterBy
      nameLike: $nameLike
      sortType: $sortType
      sortOrder: $sortOrder
    ) {
      totalCount
      data {
        id
        title
        totalTracks
        totalPlays
        tags
        imageUrl
        createdAt
      }
    }
  }
`;

export const GET_SONGS = gql`
  query listSongs(
    $limit: Int!
    $offset: Int!
    $filterBy: TimePlayedEnum
    $nameLike: String
    $sortType: MuzingoArraySortOption
    $sortOrder: MuzingoArraySortOption
  ) {
    listSongs(
      limit: $limit
      offset: $offset
      filterBy: $filterBy
      nameLike: $nameLike
      sortType: $sortType
      sortOrder: $sortOrder
    ) {
      totalCount
      data {
        id
        album
        title
        isrCode
        featuredPlaylists
        totalPlays
        artist
        createdAt
        trackUrl
        featuredOnRadio
        trackLength
      }
    }
  }
`;

export const GET_SINGLE_PLAYLIST_WITH_SONGS = gql`
  query adminSearchSongsByPlaylist($playlistId: ID!, $limit: Int!, $offset: Int!, $filterBy: TimePlayedEnum) {
    adminSearchSongsByPlaylist(playlistId: $playlistId, limit: $limit, offset: $offset, filterBy: $filterBy) {
      totalCount
      playlist {
        id
        title
        totalTracks
        totalPlays
        imageUrl
        createdAt
      }
      tracks {
        id
        album
        title
        featuredPlaylists
        totalPlays
        artist
        createdAt
        featuredOnRadio
        trackUrl
        trackLength
      }
    }
  }
`;

export const GET_PLATFORM_ANALYTICS = gql`
  query adminGetPlatformAnalytics($period: AnalyticsPeriod) {
    adminGetPlatformAnalytics(period: $period) {
      status
      message
      data {
        totalUsers
        totalGamesPlayed
        totalHosts
        totalPlayer
      }
    }
  }
`;

export const GET_TOP_USERS = gql`
  query adminGetTopPlayers($limit: Int!) {
    adminGetTopPlayers(limit: $limit) {
      totalCount
      data {
        id
        fullname
        accountActive
        totalGamesPlayed
        email
        dateRegistered
        username
        userExperience
      }
    }
  }
`;

export const GET_TRENDING_PLAYLIST = gql`
  query getTrendingPlaylist($limit: Int!) {
    getTrendingPlaylist(limit: $limit) {
      totalCount
      data {
        id
        title
        totalTracks
        totalPlays
        imageUrl
        createdAt
      }
    }
  }
`;

// TODO:
// query to getting all transaction details

export const GET_TRANSACTIONS = gql`
  query adminListAllTransactions($limit: Int!, $offset: Int!, $filterBy: TimePlayedEnum) {
    adminListAllTransactions(limit: $limit, offset: $offset, filterBy: $filterBy) {
      status
      message
      data {
        index
        stripeId
        bundleId
        bunldeTitle
        amount
        date
        userFullName
        userEmail
        userId
        receiptUrl
      }
    }
  }
`;

export const GET_BUNDLES = gql`
  query adminListBundle(
    $limit: Int!
    $offset: Int!
    $nameLike: String
    $sortType: MuzingoArraySortOption
    $sortOrder: MuzingoArraySortOption
  ) {
    adminListBundle(limit: $limit, offset: $offset, nameLike: $nameLike, sortType: $sortType, sortOrder: $sortOrder) {
      totalCount
      data {
        id
        title
        costOfAcquisition
        defaultMaxPlaylist
        maxGameParticipant
        discount
        subscriptionType
      }
    }
  }
`;

export const GET_SONG_INFO = gql`
  query getSongById($id: ID!) {
    getSongById(id: $id) {
      status
      message
      data {
        id
        title
        featuredPlaylists
        artist
        album
        totalPlays
        trackUrl
        featuredOnRadio
        createdAt
      }
    }
  }
`;

export const GET_CURRENT_RADIO_PLAY = gql`
  query getCurrentRadioStationId($gameCode: String!, $playlistId: String) {
    getCurrentRadioStationId(gameCode: $gameCode, playlistId: $playlistId) {
      status
      message
      playlistId
      radioUrl
    }
  }
`;

export const GET_TRIAL_CAMPAIGN = gql`
  query adminListTrialCampaign($limit: Int!, $offset: Int!) {
    adminListTrialCampaign(limit: $limit, offset: $offset) {
      totalCount
      data {
        id
        title
        startDate
        endDate
        campaingClass
        noOfTrialDays
        noOfTrialGames
        campaignType
        selectablePlaylistIds
        active
      }
    }
  }
`;

export const GET_FREE_TRIAL_PLAYLIST = gql`
  query listPlaylist($limit: Int!, $offset: Int!) {
    listPlaylist(limit: $limit, offset: $offset) {
      totalCount
      data {
        id
        title
      }
    }
  }
`;
