import Input from "../../components/input";
import React from "react";
import Button from "../../components/button";
import {showErrorNotification, showSuccessNotification} from "../../lib/notificationManager";
import miniLogo from "../../assets/image/miniLogo.png";
import styled from "styled-components";
import Colour from "../../lib/colour";
import {Link} from "react-router-dom";
import { routes } from "../../components/navigation/routes";
import EmailLogo from "../../assets/image/email.svg";
import {useMutation} from "@apollo/client";
import {FORGOT_PASSWORD} from "../../lib/graphQl/mutation";

const FormWrapper = styled.div`
    background-color: ${Colour.White};
    border-radius: 5px;
    padding: 48px;
    max-width: 50%;
`;

const Logo = styled.img`
    width: 60px;
    height: 60px;
`;

const EmailLogoImage = styled.img`
    width: 100px;
    height: 100px;
    margin-right: auto;
    margin-left: auto;
`;

const LoginText = styled.span`
    display: block;
    font-size: 32px;
    font-weight: 700;
    margin-top: 32px;
    margin-bottom: 32px;
`;

const LastWrapper = styled.div`
    margin-top: 32px;
    margin-bottom: 32px;
`;

const ImageWrapper = styled.div`
    margin-top: 32px;
    margin-bottom: 32px;
    display: flex;
    justify-center: center;
    align-items: center;
`;

const BackToLogin = styled.span`
    font-size: 16px;
    font-weight: 500;
    
    ${props => props.isReset && `
        text-align: center;
        display: block;
        margin-top: 16px;
    `}
    
    > a {
        text-decoration: none;
        color: ${Colour.Primary};
    }
`;

const MessageContent = styled.span`
    text-align: center;
    font-size: 16px;
    margin-bottom: 32px;
    display: block;
`;

const Title = styled.span`
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    display: block;
`;

export default function ForgotPassword() {
    const [saving, setResetting] = React.useState(false);
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [emailError, setEmailError] = React.useState("");

    const [sendPasswordLink] = useMutation(FORGOT_PASSWORD);

    async function onSubmit(event) {
        event.preventDefault();
        setResetting(true);
        try {
            let shouldSubmit = true;
            if (!email.trim()) {
                shouldSubmit = false;
                setEmailError("Password must be filled");
            } else if (emailError.trim()) {
                setEmailError("")
            }

            if (shouldSubmit) {
                const response = await sendPasswordLink({ variables: { email } });

                if (response.data?.forgotPassword?.status === "success") {
                    showSuccessNotification(response.data?.forgotPassword?.message);
                    setIsSubmitted(true);
                }

                if (response.data?.forgotPassword?.status === "failed") {
                    showErrorNotification(response.data?.forgotPassword?.message);
                }
            }
        } catch (e) {
            showErrorNotification("There is an error sending your reset password link");
        } finally {
            setResetting(false);
        }
    }

    const SuccessMessage = (
        <>
            <Title>Confirmation</Title>
            <ImageWrapper>
                <EmailLogoImage src={EmailLogo} alt="email-logo" />
            </ImageWrapper>
            <MessageContent>
                To be sure you're not a robot, we sent a mail to the email address you provided during registration.
            </MessageContent>
            <MessageContent>
                If you cannot find an email from us in your inbox, please click the "Resend Email" button below.
                Don’t forget to check the junk folder. Perhaps our postman lost his way.
            </MessageContent>
            <Button loading={saving} fullWidth onClick={onSubmit}>Resend Email</Button>
            <BackToLogin isReset>
                <Link to={routes.login}>Back to Log in</Link>
            </BackToLogin>
        </>
    );

    return (
        <div id="auth-wrapper">
            <FormWrapper>
                {isSubmitted
                    ? SuccessMessage
                    : (
                        <>
                            <Logo src={miniLogo} alt="logo"/>
                            <LoginText>Password recovery</LoginText>
                            <form onSubmit={onSubmit} autoComplete="on">
                                <Input
                                    label="Email"
                                    value={email}
                                    onChange={setEmail}
                                    type="email"
                                    error={emailError}
                                    autoComplete="email"
                                    required
                                />
                                <LastWrapper>
                                    <Button type="submit" loading={saving} fullWidth>Recover password</Button>
                                </LastWrapper>
                                <BackToLogin>
                                    Remember password? <Link to={routes.login}>Back to Log in</Link>
                                </BackToLogin>
                            </form>
                        </>
                    )
                }
            </FormWrapper>
        </div>
    )
}
