import React from "react";
import Input from "../input";
import styled from "styled-components";
import Colour from "../../lib/colour";
import PropTypes from "prop-types";
import { useDebounce } from "use-lodash-debounce";
import Select from "../select";

const Tools = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${(props) =>
        props.rightAlign ? "flex-end" : "space-between"};
    margin-bottom: 32px;
`;

const IconWrapper = styled.span`
    border: 1px solid ${Colour.DeepGrey};
    padding: 10px 16px;
    border-radius: 6px;
    color: ${Colour.Primary};
    margin-right: 12px;
    cursor: pointer;
`;

const RightTools = styled.div`
    display: flex;
`;

const sortOptions = [
    {
        label: "Date-Descending",
        value: "date_descending",
        sort: "DESCENDING",
        type: "DATE",
    },
    {
        label: "Date-Ascending",
        value: "date_ascending",
        sort: "ASCENDING",
        type: "DATE",
    },
    {
        label: "Alphabet-Ascending",
        value: "alphabet_ascending",
        sort: "ASCENDING",
        type: "ALPHABET",
    },
    {
        label: "Alphabet-Descending",
        value: "alphabet_descending",
        sort: "DESCENDING",
        type: "ALPHABET",
    },
];
const sortPlayOptions = [
    {
        label: "Most Played",
        value: "most_played",
        sort: "DESCENDING",
        type: "NUMBER",
    },
    {
        label: "Least Played",
        value: "least_played",
        sort: "ASCENDING",
        type: "NUMBER",
    },
];

export default function TableTools(props) {
    const [searchQuery, setSearchQuery] = React.useState("");

    const debouncedValue = useDebounce(searchQuery, 1000);

    React.useEffect(() => {
        if (props.onSearch && searchQuery.trim() !== props.searchValue) {
            props.onSearch(searchQuery.trim() || undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue]);

    function onSearch(searchInput) {
        setSearchQuery(searchInput);
    }

    return (
        <Tools rightAlign={!props.canSearch}>
            {props.canSearch && (
                <Input
                    value={searchQuery}
                    onChange={onSearch}
                    placeholder={props.searchPlaceholder}
                    isAppInput
                />
            )}
            <RightTools>
                {props.canDownload && (
                    <IconWrapper onClick={props.onDownload}>
                        <i className="fas fa-file-download" />
                    </IconWrapper>
                )}
                {props.canSortPlayCount && (
                    <Select
                        value={props.sortPlayValue?.value}
                        onSelect={props.onSortPlaySelect}
                        options={props.sortPlayOptions || sortPlayOptions}
                        iconValue="fas fa-sort-amount-up"
                        hasIcon
                    />
                )}
                {props.canSort && (
                    <Select
                        value={props.sortValue?.value}
                        onSelect={props.onSortSelect}
                        options={sortOptions}
                        iconValue="fas fa-sort-amount-up"
                        hasIcon
                    />
                )}
            </RightTools>
        </Tools>
    );
}

TableTools.propTypes = {
    canSearch: PropTypes.bool,
    canDownload: PropTypes.bool,
    canSort: PropTypes.bool,
    canSortPlayCount: PropTypes.bool,
    onSearch: PropTypes.func,
    onDownload: PropTypes.func,
    searchPlaceholder: PropTypes.string,
    searchValue: PropTypes.string,
    sortValue: PropTypes.object,
    sortPlayValue: PropTypes.object,
    onSortSelect: PropTypes.func,
    onSortPlaySelect: PropTypes.func,
};

TableTools.defaultProps = {
    sortValue: {
        label: "Date-Descending",
        value: "DATE",
        sort: "DESCENDING",
    },
    sortPlayValue: {
        label: "Most Played",
        value: "NUMBER",
        sort: "DESCENDING",
    },
};
