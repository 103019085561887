import { Modal as RModal } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import TextContent from '../textContent';
import styled from 'styled-components';

const Body = styled.div`
  > span:first-child {
    margin-bottom: 32px;
  }
`;

export default function Modal(props) {
  return (
    <RModal
      visible={props.isActive}
      onCancel={props.onCancel}
      footer={null}
      closable={false}
      width={480}
      centered
      destroyOnClose
    >
      <Body>
        <TextContent fontSize={24} fontWeight="700" textAlign="center" display="block">
          {props.title}
        </TextContent>
        {props.children}
      </Body>
    </RModal>
  );
}

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  isActive: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.any.isRequired,
};
