import React from "react";
import TextContent from "../../components/textContent";
import Colour from "../../lib/colour";
import PageTitle from "../../components/pageTitle";
import TableTools from "../../components/tableTools";
import Table from "../../components/table";
import styled from "styled-components";
import Button from "../../components/button";
import Modal from "../../components/modal";
import Input from "../../components/input";
import {showErrorNotification, showSuccessNotification} from "../../lib/notificationManager";
import {gql, useMutation, useQuery} from "@apollo/client";
import {GET_ADMINS} from "../../lib/graphQl/query";
import { DateTime } from "luxon";
import {CREATE_ADMIN, DEACTIVATE_ADMIN, UPDATE_ADMIN, ACTIVATE_ADMIN} from "../../lib/graphQl/mutation";
import downloadAsCSV from "../../lib/exportCSV";
import {authContext} from "../../components/authWrapper";

const IconWrapper = styled.div`
    > span {
        font-size: 20px;
        color: ${Colour.DeepGrey};
        cursor: pointer;
        padding: 8px;
        
        :first-child {
            margin-right: 16px;
        }
    }
`;

const Title = styled.span`
    font-size: 16px;
    color: ${Colour.DeepGrey};
`;

const Form = styled.form`
    > button: {
        margin-top: 32px;
    }
    > div {
        margin-bottom: 24px;
    }
`;

const pageLimit = 20;
export default function Admin() {
    const authContextProps = React.useContext(authContext);
    const [editAdminId, setEditAdminId] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [emailError, setEmailError] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [passwordError, setPasswordError] = React.useState("");
    const [name, setName] = React.useState("");
    const [nameError, setNameError] = React.useState("");
    const [saving, setSaving] = React.useState(false);
    const [isNewAdminModalOpen, setIsNewAdminModalOpen] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(false);
    const [editingAdmin, setEditingAdmin] = React.useState({});
    const [searchQuery, setSearchQuery] = React.useState(undefined);
    const [page, setPage] = React.useState(1);
    const [offset, setOffset] = React.useState(0);
    const [selectedRow, setSelectedRow] = React.useState([]);
    const [sortValue, setSortValue] = React.useState({
        label: "Date-Descending",
        value: "date_descending",
        sort: "DESCENDING",
        type: "DATE",
    });

    const { data, loading } = useQuery(GET_ADMINS, {
        variables: {
            limit: pageLimit,
            offset,
            nameLike: searchQuery,
            sortType: sortValue.type,
            sortOrder: sortValue.sort,
        }
    });

    const [deactivateAdmin] = useMutation(DEACTIVATE_ADMIN);
    const [activateAdmin] = useMutation(ACTIVATE_ADMIN);
    const [createAdmin] = useMutation(CREATE_ADMIN, {
        update:  (cache, { data: { adminCreateNewStaff } }) => {
            cache.modify({
                fields: {
                    adminListStaff(existingListStaff) {
                        const newListStaffRef = cache.writeFragment({
                            data: adminCreateNewStaff.data,
                            fragment: gql`
                                fragment NewStaff on Staff {
                                    id
                                    fullname
                                    email
                                    dateRegistered
                                    accountActive
                                }
                            `
                        });
                        return {
                            totalCount: existingListStaff.totalCount + 1,
                            data: [...existingListStaff.data, newListStaffRef],
                        }
                    }
                }
            });
        },
    });
    const [updateAdmin] = useMutation(UPDATE_ADMIN);

    const columns = [
        {
            title: "ADMIN USERS",
            dataIndex: "firstName",
        /* sorter: (a, b) => a.type.localeCompare(b.type),*/
            render: (_, record) => (
                <TextContent fontSize={14} fontWeight="500" colour={Colour.BlackText}>
                    {record.fullname}
                </TextContent>
            ),
        },
        { title: "EMAIL ADDRESSES", dataIndex: "email", /*sorter: (a, b) => a.type.localeCompare(b.type) */ },
        {
            title: "CREATED",
            dataIndex: "dateRegistered",
            sorter: (a, b) => DateTime.fromISO(a.dateRegistered).toMillis() - DateTime.fromISO(b.dateRegistered).toMillis(),
            render: time => (
                <TextContent fontSize={14} colour={Colour.BlackText}>
                    {DateTime.fromISO(time).toFormat("dd-MM-y")}
                </TextContent>
            ),
        },
        {
            title: (
                <Title>
                    <i className="far fa-trash-alt" />
                </Title>
            ),
            dataIndex: "id",
            align: "center",
            render: (id, record) => {
                if (authContextProps.user.id === id) {
                    return null;
                }
                const onEdit = () => onAdminEdit(record);
                const onDeactivate = () => onAdminDeactivate(id);
                const onActivate = () => onAdminActivate(id);
                return (
                    <IconWrapper>
                        {record.accountActive
                            ? (
                                <span onClick={onDeactivate}>
                                    {/* <i className="fas fa-ban" /> */}
                                    <i className="far fa-trash-alt" />
                                </span>
                            )
                            : (
                                <span onClick={onActivate}>
                                    <i className="fas fa-check-double" />
                                </span>
                            )
                        }
                        <span onClick={onEdit}>
                            <i className="far fa-edit" />
                        </span>
                    </IconWrapper>
                )
            }
        },
    ];

    async function onSubmit(event) {
        event.preventDefault();
        setSaving(true);
        try {
            let shouldSubmit = true;
            if (!email.trim()) {
                shouldSubmit = false;
                setEmailError("Email must be filled");
            } else if (emailError.trim()) {
                setEmailError("")
            }
            if (!isEditing && !password.trim()) {
                shouldSubmit = false;
                setPasswordError("Password must be filled");
            } else if (password.trim().length < 6) {
                shouldSubmit = false;
                setPasswordError("Password must be at least six characters");
            } else if (passwordError.trim()) {
                setPasswordError("")
            }
            if (!name.trim()) {
                shouldSubmit = false;
                setNameError("Full name must be filled");
            } else if (nameError.trim()) {
                setNameError("")
            }

            if (isEditing && shouldSubmit) {
                const resp = await updateAdmin({
                    variables: {
                        id: editAdminId,
                        firstName: name,
                        email,
                    }
                });
                processResponse(resp.data?.adminEditStaff);
            } else if (shouldSubmit) {
                const resp = await createAdmin({
                    variables: {
                        fullname: name,
                        email,
                        password,
                    }
                });
                processResponse(resp.data?.adminCreateNewStaff);
            }

        } catch (e) {
            showErrorNotification(`There is an error ${isEditing ? "editing" : "adding new"} admin`);
        } finally {
            setSaving(false);
        }
    }

    function processResponse(response) {
        if (response?.status === "success") {
            onClose();
            showSuccessNotification(response.message);
        }

        if (response?.status === "failed") {
            showErrorNotification(response.message);
        }
    }

    function resetFields() {
        setEditingAdmin(null);
        setIsEditing(false);
        setName("");
        setPassword("");
        setEmail("");
    }

    function toggleAdminModal() {
        setIsNewAdminModalOpen(!isNewAdminModalOpen);
    }

    function onClose() {
        resetFields();
        toggleAdminModal();
    }

    function onAdminEdit(aparam) {
        setEditingAdmin(aparam);
        setEditAdminId(aparam.id);
        setName(aparam.fullname);
        setEmail(aparam.email);
        // setPassword(aparam.password);
        setIsEditing(true);
        toggleAdminModal();
    }

    async function onAdminDeactivate(adminId) {
        const resp = await deactivateAdmin({
            variables: { id: adminId }
        });

        if (resp?.data?.adminDeactivateUser?.status === "success") {
            showSuccessNotification(resp.data.adminDeactivateUser.message);
        }

        if (resp?.data?.adminDeactivateUser?.status === "failed") {
            showErrorNotification(resp.data.adminDeactivateUser.message);
        }
    }

    async function onAdminActivate(adminId) {
        const resp = await activateAdmin({
            variables: { id: adminId }
        });

        if (resp?.data?.adminActivateUser?.status === "success") {
            showSuccessNotification(resp.data.adminActivateUser.message);
        }

        if (resp?.data?.adminActivateUser?.status === "failed") {
            showErrorNotification(resp.data.adminActivateUser.message);
        }
    }

    function onDownload() {
        if (data?.adminListStaff?.data) {
            downloadAsCSV(data.adminListStaff.data.map(admin => ({
                name: admin.fullname,
                email: admin.email,
                createdAt: DateTime.fromISO(admin.dateRegistered).toFormat("d-M-y")
            })), "Admins")
        }
    }

    function onPrevPage() {
        setOffset((page - 2) * pageLimit);
        setPage(prevPage => prevPage - 1);
    }

    function onNextPage() {
        setOffset(page * pageLimit);
        setPage(prevPage => prevPage + 1);
    }

    function onSortChange(newSortValue) {
        setSortValue({...newSortValue});
    }

    return (
        <div>
            <PageTitle
                title="Administrators"
                rightComponent={(
                    <Button onClick={toggleAdminModal}>
                        <TextContent fontSize={14} fontWeight="600" colour={Colour.White}>New Admin</TextContent>
                    </Button>
                )}
            />
            <TableTools
                onSearch={setSearchQuery}
                onDownload={onDownload}
                searchPlaceholder="Search administrators"
                searchValue={searchQuery}
                sortValue={sortValue}
                onSortSelect={onSortChange}
                canSearch
                canDownload
                canSort
            />
            <Table
                columns={columns}
                data={data?.adminListStaff?.data || []}
                limit={pageLimit}
                total={data?.adminListStaff?.totalCount || 1}
                page={page}
                showPagination
                canSelectRows
                loading={loading}
                selectedRow={selectedRow}
                onSelectedRowChange={setSelectedRow}
                onPrevPage={onPrevPage}
                onNextPage={onNextPage}
            />
            <Modal
                isActive={isNewAdminModalOpen}
                onCancel={onClose}
                title={isEditing ? "Edit Administrator" : "New Administrator"}
            >
                <Form onSubmit={onSubmit} autoComplete="on">
                    <Input
                        label="Full Name"
                        value={name}
                        onChange={setName}
                        error={nameError}
                        autoComplete="name"
                        required
                    />
                    <Input
                        label="Email Address"
                        value={email}
                        onChange={setEmail}
                        error={emailError}
                        autoComplete="email"
                        required
                    />
                    {!isEditing && (
                        <Input
                            label="Password"
                            value={password}
                            onChange={setPassword}
                            type="password"
                            error={passwordError}
                            autoComplete="current-password"
                            required
                        />
                    )}
                    <Button type="submit" loading={saving} fullWidth>
                        {isEditing ? "Save Changes" : "Add Admin"}
                    </Button>
                </Form>
            </Modal>
        </div>
    )
}
