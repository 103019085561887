import React from 'react';
import Table from '../../components/table';
import TextContent from '../../components/textContent';
import Colour from '../../lib/colour';
import Tag from '../../components/tag';
import PageTitle from '../../components/pageTitle';
import TableTools from '../../components/tableTools';
import { useQuery } from '@apollo/client';
import { GET_BUNDLES, GET_USERS } from '../../lib/graphQl/query';
import downloadAsCSV from '../../lib/exportCSV';
import { dateComparator } from '../../lib/sort';
import { DateTime } from 'luxon';

const pageLimit = 20;
export default function Users() {
  const [page, setPage] = React.useState(1);
  const [offset, setOffset] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState(null);
  const [allBundleData, setAllBundleData] = React.useState({});
  const [sortValue, setSortValue] = React.useState({
    label: 'Date-Descending',
    value: 'date_descending',
    sort: 'DESCENDING',
    type: 'DATE',
  });

  const { data, loading } = useQuery(GET_USERS, {
    variables: {
      limit: pageLimit,
      offset: offset,
      nameLike: searchQuery,
      sortType: sortValue.type,
      sortOrder: sortValue.sort,
    },
  });
  const { loading: bundleLoading } = useQuery(GET_BUNDLES, {
    variables: {
      limit: 10,
      offset: 0,
    },
    onCompleted: (data) => {
      setAllBundleData({ ...data });
    },
  });
  console.log(allBundleData);

  const columns = [
    {
      title: 'USERS',
      dataIndex: 'fullname',
      fixed: 'left',
      width: 180,
      render: (text) => (
        <TextContent fontSize={14} fontWeight="500" colour={Colour.BlackText}>
          {text}
        </TextContent>
      ),
    },
    { title: 'EMAIL ADDRESSES', dataIndex: 'email' },
    {
      title: 'TYPE',
      dataIndex: 'userExperience',
      //   filters: [
      //     {
      //       text: "Player",
      //       value: "Player",
      //     },
      //     {
      //       text: "Host",
      //       value: "Host",
      //     },
      //   ],
      //   onFilter: (value, record) => record.userExperience.indexOf(value) === 0,
      sorter: (a, b) => a.userExperience.length - b.userExperience.length,
      align: 'center',
      render: (text) => (
        <TextContent fontSize={14} colour={Colour.BlackText}>
          {text.join(', ')}
        </TextContent>
      ),
    },
    {
      title: 'ACTIVE PLAN',
      dataIndex: 'activePlan',
      sorter: (a, b) => a.activePlan.length - b.activePlan.length,
      align: 'center',
      render: (isPlanActive) => (
        <Tag tagLabel={isPlanActive ? 'Active' : 'Inactive'} variant={isPlanActive ? 'success' : 'error'} />
      ),
    },
    {
      title: 'PLAN TYPE',
      dataIndex: 'userBundleType',
      sorter: (a, b) => a.userBundleType.length - b.userBundleType.length,
      align: 'center',
      render: (text) =>
        allBundleData?.adminListBundle?.data.map((bundleInfo) => {
          if (bundleInfo.id == text) {
            return (
              <TextContent key={bundleInfo.id} fontSize={14} colour={Colour.BlackText}>
                {bundleInfo.title}
              </TextContent>
            );
          } else {
            return (
              <TextContent key={bundleInfo.id} fontSize={14} colour={Colour.BlackText}>
                {''}
              </TextContent>
            );
          }
        }),
    },
    { title: 'GAMES PLAYED', dataIndex: 'totalGamesPlayed', align: 'center' },
    {
      title: 'STATUS',
      dataIndex: 'accountActive',
      // sorter: (a, b) => a.accountActive && !b.accountActive,
      sorter: (a, b) => a.accountActive && b.accountActive,
      align: 'center',
      render: (isActive) => (
        <Tag tagLabel={isActive ? 'Active' : 'Inactive'} variant={isActive ? 'success' : 'error'} />
      ),
    },
    {
      title: 'CREATED',
      dataIndex: 'dateRegistered',
      //   sorter: (a, b) => dateComparator(a.createdAt, b.createdAt),
      sorter: (a, b) => DateTime.fromISO(a.dateRegistered).toMillis() - DateTime.fromISO(b.dateRegistered).toMillis(),
      fixed: 'right',
      width: 120,
      render: (time) => (
        <TextContent fontSize={14} colour={Colour.BlackText}>
          {DateTime.fromISO(time).toFormat('dd-MM-y')}
        </TextContent>
      ),
    },
  ];

  function onDownload() {
    if (data?.adminListUsers?.data) {
      downloadAsCSV(
        data.adminListUsers.data.map((user) => ({
          name: user.fullname,
          type: user.userExperience.join(', '),
          totalGamesPlayed: user.totalGamesPlayed,
        })),
        'Users'
      );
    }
  }

  function onPrevPage() {
    setOffset((page - 2) * pageLimit);
    setPage((prevPage) => prevPage - 1);
  }

  function onNextPage() {
    setOffset(page * pageLimit);
    setPage((prevPage) => prevPage + 1);
  }

  function onSortChange(newSortValue) {
    setSortValue({ ...newSortValue });
  }

  return (
    <div>
      <PageTitle title="Users" />
      <TableTools
        onSearch={setSearchQuery}
        onDownload={onDownload}
        searchPlaceholder="Search by email"
        searchValue={searchQuery}
        sortValue={sortValue}
        onSortSelect={onSortChange}
        canSearch
        canDownload
        canSort
      />
      <Table
        columns={columns}
        data={data?.adminListUsers?.data || []}
        loading={loading}
        page={page}
        limit={pageLimit}
        total={data?.adminListUsers?.totalCount || 1}
        showPagination
        onPrevPage={onPrevPage}
        onNextPage={onNextPage}
      />
    </div>
  );
}
