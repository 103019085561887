const Colour = {
    GreyBorder: "#C5DBEB",
    Grey: "#A2A2A2",
    Gold: "#FFD559",
    DeepGrey: "#B9B9B9",
    TextGrey: "#6B7280",
    Subdue: "#505050",
    Black: "#000",
    BlackText: "#111827",
    GreyLight: "#32435C",
    White: "#fff",
    WhiteBlue: "#F3FBFF",
    Primary: "#59348B",
    PrimaryDeep: "#311D4E",
    PrimaryFade: "#ECDAFF",
    Error: "#E24C4C",
    Rose: "#9F1239",
    ErrorFade: "#FBEAEB",
    NotificationError: "#FB7185",
    NotificationErrorFade: "#FFF1F2",
    Success: "#3CD498",
    SuccessFade: "#E8FAF5",
    NotificationSuccess: "#008556",
    NotificationSuccessFade: "#D6F3E2",
}

export default Colour;
