import React from "react";
import TextContent from "../../components/textContent";
import Colour from "../../lib/colour";
import PageTitle from "../../components/pageTitle";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { routes } from "../../components/navigation/routes";

const AnalyticsCard = styled.div`
    border-radius: 5px;
    padding: 28px;
    background-color: ${(props) => props.colour};
    max-width: 270px;
    flex: 1;
`;

const AnalyticsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
        margin-right: 16px;

        :last-child {
            margin-right: none;
        }
    }
`;
export default function Settings() {
    return (
        <div>
            <PageTitle
                title="Settings"
                subtitle="Manage Application Settings"
            />
            <Link to={routes.settingsFreeTrial}>
                <AnalyticsWrapper>
                    <AnalyticsCard colour={Colour.PrimaryDeep}>
                        <TextContent
                            textAlign="left"
                            display="block"
                            fontSize={20}
                            fontWeight="700"
                            colour={Colour.White}
                        >
                            Free Trial
                        </TextContent>
                        <TextContent
                            textAlign="left"
                            fontSize={12}
                            colour={Colour.White}
                            display="block"
                        >
                            Manage free trials for all users
                        </TextContent>
                    </AnalyticsCard>
                </AnalyticsWrapper>
            </Link>
        </div>
    );
}
