import React from "react";
import TextContent from "../../components/textContent";
import Colour from "../../lib/colour";
import PageTitle from "../../components/pageTitle";
import Button from "../../components/button";
import TableTools from "../../components/tableTools";
import Table from "../../components/table";
import Tab from "../../components/tab";
import { generateParamUrl, routes } from "../../components/navigation/routes";
import styled from "styled-components";
import {Link} from "react-router-dom";
import Tag from "../../components/tag";
import NewOrEditPlaylistModal from "../../components/newOrEditPlaylistModal";
import {gql, useMutation, useQuery} from "@apollo/client";
import { GET_PLAYLIST,GET_TRANSACTIONS } from "../../lib/graphQl/query";
import {DateTime} from "luxon";
import {dateComparator} from "../../lib/sort";
import {showErrorNotification, showSuccessNotification} from "../../lib/notificationManager";
import {updatePlaylistBanner} from "../../lib/restService";
import downloadAsCSV from "../../lib/exportCSV";
import { DELETE_PLAYLIST } from "../../lib/graphQl/mutation";

const TabItems = [
    { name: "Today", key: "TODAY" },
    { name: "This Week", key: "THISWEEK" },
    { name: "This Month", key: "THISMONTH" },
    { name: "All Time", key: null },
]

const IconWrapper = styled.div`
    > span {
        font-size: 20px;
        color: ${Colour.DeepGrey};
        cursor: pointer;
        padding: 8px;
        
        :first-child {
            margin-right: 16px;
        }
    }
`;

const Title = styled.span`
    font-size: 16px;
    color: ${Colour.DeepGrey};
`;

const pageLimit = 20;

export default function Transactions() {
    const [activeTab, setActiveTab] = React.useState(null);
    const [isModalActive, setModalActive] = React.useState(false);
    const [isProcessing, setProcessing] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [offset, setOffset] = React.useState(0);
    const [editingPlaylist, setEditingPlaylist] = React.useState(null);
    const [searchQuery, setSearchQuery] = React.useState(null);
    const [sortValue, setSortValue] = React.useState({
        label: "Date-Descending",
        value: "date_descending",
        sort: "DESCENDING",
        type: "DATE",
    });

    const { data, loading } = useQuery(GET_TRANSACTIONS, {
        variables: {
            offset,
            limit: pageLimit,
            filterBy: activeTab,
            // nameLike: searchQuery,
            // sortType: sortValue.type,
            // sortOrder: sortValue.sort,
        }
    });
    console.log(data);
    // const [deletePlaylist] = useMutation(DELETE_PLAYLIST, {
    //     update:  (cache, { data: { adminDeletePlaylist } }) => {
    //         if (adminDeletePlaylist.deletedPlaylistId) {
    //             cache.modify({
    //                 fields: {
    //                     listPlaylist(existingListPlaylist, { readField }) {
    //                         return {
    //                             totalCount: existingListPlaylist.totalCount - 1,
    //                             data: existingListPlaylist.data.filter(
    //                                 playlistRef => readField("id", playlistRef) !== adminDeletePlaylist.deletedPlaylistId
    //                             ),
    //                         }
    //                     }
    //                 }
    //             });
    //         }
    //     }
    // });
    const columns = [
        {
            title: (
                <TextContent fontSize={12} fontWeight={"500"} colour={Colour.TextGrey}>
                  USER
                </TextContent>
              ),
            dataIndex: "userFullName",
            align: "center",
            render: text => (
                    // <Link to={generateParamUrl(routes.viewPlaylist, record.id)}>
                    <TextContent fontSize={14} colour={Colour.TextGrey}>
                        {text}
                    </TextContent>
                    // </Link>
            ),
        },
        {
            title: (
                <TextContent fontSize={12} fontWeight={"500"} colour={Colour.TextGrey}>
                  PRICE
                </TextContent>
              ),
            dataIndex: "amount",
            align: "center",
            render: price => (
                <TextContent fontSize={14} colour={Colour.TextGrey}>
                    {`$${price}`}
                </TextContent>
            ),
        },
        {
            title: (
                <TextContent fontSize={12} fontWeight={"500"} colour={Colour.TextGrey}>
                  PACKAGE NAME
                </TextContent>
              ), dataIndex: "bunldeTitle", align: "center",
            render: pkgname => (
            <TextContent fontSize={14} colour={Colour.TextGrey}>
                {pkgname || "-"}
            </TextContent>
            ),
        },
        {
            title: (
                <TextContent fontSize={12} fontWeight={"500"} colour={Colour.TextGrey}>
                  DISCOUNT
                </TextContent>
              ), dataIndex: "discount", align: "center",
            render: discount =>(
                    <TextContent fontSize={14} colour={Colour.TextGrey}>
                        {discount  ? `${discount}%` : "0"}
                    </TextContent>
            ),
        },
        {
            title: (
                <TextContent fontSize={12} fontWeight={"500"} colour={Colour.TextGrey}>
                  CREATED
                </TextContent>
              ),
            dataIndex: "date",
            sorter: (a, b) => dateComparator(new Date(a.date * 1000).toJSON(), new Date(b.date * 1000).toJSON()),
            render: time => (
                    <TextContent fontSize={14} colour={Colour.BlackText}>
                        {DateTime.fromISO(new Date(time * 1000).toJSON()).toFormat("dd-MM-y")}
                    </TextContent>
                ),
        },
        {
            title: "",
            align: "center",
            render: record => (
                <IconWrapper>
                    {/* <span onClick={onDeletePlaylist(record.id)}> */}
                    <span >
                        <i className="far fa-trash-alt" />
                    </span>
                    {/* <span onClick={onEditPlaylist(record)}>
                        <i className="far fa-edit" />
                    </span> */}
                </IconWrapper>
            ),
        },
    ];

    // function onDeletePlaylist(playlistId) {
    //     return async () => {
    //         const resp = await deletePlaylist({
    //             variables: { id: playlistId }
    //         });

    //         if (resp?.data?.adminDeletePlaylist?.status === "success") {
    //             showSuccessNotification(resp.data.adminDeletePlaylist.message);
    //         }

    //         if (resp?.data?.adminDeletePlaylist?.status === "failed") {
    //             showErrorNotification(resp.data.adminDeletePlaylist.message);
    //         }
    //     }
    // }

    // function onEditPlaylist(record) {
    //     return () => {
    //         if (!isProcessing) {
    //             setEditingPlaylist(record);
    //             setModalActive(true);
    //         }
    //     }
    // }

    // async function onSubmit(createPlaylistData) {
    //     setProcessing(true);
    //     const resp = await createPlaylist({
    //         variables: {
    //             title: createPlaylistData.title,
    //             description: `${createPlaylistData.title}-description`,
    //         }
    //     })

    //     if (resp?.data?.adminCreatePlaylist?.status === "success") {
    //         showSuccessNotification(resp.data.adminCreatePlaylist.message);
    //         onCloseModal();
    //     }

    //     if (resp?.data?.adminCreatePlaylist?.status === "failed") {
    //         showErrorNotification(resp.data.adminCreatePlaylist.message);
    //     }
    //     setProcessing(false);
    // }

    // async function onEditSubmit(editPlaylistData) {
    //     try {
    //         setProcessing(true);
    //         let response = {
    //             data: {
    //                 link: editPlaylistData.image,
    //                 status: "success",
    //             }
    //         };

    //         if (editPlaylistData.file) {
    //             const formData = new FormData();
    //             formData.append("playlistId", editPlaylistData.id);
    //             formData.append("intention", "playlist");
    //             formData.append("file", editPlaylistData.file);

    //             response = await updatePlaylistBanner(formData);
    //         }

    //         if (response.data?.status === "success") {
    //             const resp = await editPlaylist({
    //                 variables: {
    //                     id: editPlaylistData.id,
    //                     title: editPlaylistData.title,
    //                     description: `${editPlaylistData.title}-description`,
    //                     imageUrl: response.data.link,
    //                 }
    //             })

    //             if (resp?.data?.adminEditPlaylist?.status === "success") {
    //                 showSuccessNotification(resp.data.adminEditPlaylist.message);
    //                 onCloseModal();
    //             }

    //             if (resp?.data?.adminEditPlaylist?.status === "failed") {
    //                 showErrorNotification(resp.data.adminEditPlaylist.message);
    //             }
    //         }

    //         if (response.data?.status === "failed") {
    //             showErrorNotification(response.data.message);
    //         }
    //     } catch (e) {
    //         showErrorNotification("There is an error updating playlist");
    //     } finally {
    //         setProcessing(false);
    //     }
    // }

    function onPrevPage() {
        setOffset((page - 2) * pageLimit);
        setPage(prevPage => prevPage - 1);
    }

    function onNextPage() {
        setOffset(page * pageLimit);
        setPage(prevPage => prevPage + 1);
    }

    function onDownload() {
        if (data?.adminListAllTransactions?.data) {
            downloadAsCSV(data.adminListAllTransactions.data.map(transaction => ({
                user: transaction.userFullName,
                price: transaction.amount,
                packageName: transaction.bunldeTitle,
                // discount: transaction.discount,
                createdAt: DateTime.fromISO(new Date(transaction.date * 1000).toJSON()).toFormat("dd-MM-y"),
            })), "Transactions")
        }
    }

    function onSortChange(newSortValue) {
        setSortValue({...newSortValue});
    }
  
    return (
        <div>
            <PageTitle
                title="Transactions"
                // rightComponent={(
                //     <Button onClick={onNewPlaylist} disabled={isProcessing}>
                //         <TextContent fontSize={14} fontWeight="600" colour={Colour.White}>New Playlist</TextContent>
                //     </Button>
                // )}
            />
            <TableTools
                // onSearch={setSearchQuery}
                onDownload={onDownload}
                searchPlaceholder="Search Transaction"
                // searchValue={searchQuery}
                // sortValue={sortValue}
                // onSortSelect={onSortChange}
                canSearch
                canDownload
                canSort
            />
            <Tab activeTab={activeTab} items={TabItems} onTabSelect={setActiveTab} />
            <Table
                total={data?.adminListAllTransactions?.total || 1}
                columns={columns}
                data={data?.adminListAllTransactions?.data || []}
                onNextPage={onNextPage}
                onPrevPage={onPrevPage}
                loading={loading}
                page={page}
                limit={pageLimit}
                showPagination
                canSelectRows 
            />
            {/* <NewOrEditPlaylistModal
                isEdit={!!editingPlaylist}
                isActive={isModalActive}
                editingPlaylist={editingPlaylist}
                id={editingPlaylist?.id}
                name={editingPlaylist?.title}
                image={editingPlaylist?.imageUrl}
                onClose={onCloseModal}
                onSubmit={!!editingPlaylist ? onEditSubmit : onSubmit}
                isProcessing={isProcessing}
            /> */}
        </div>
    );
    
}
