import { useQuery } from '@apollo/client';
import React from 'react'
import { GET_CURRENT_RADIO_PLAY } from '../../lib/graphQl/query';
import TextContent from '../textContent'
import Colour from "../../lib/colour";
import styled from "styled-components";
import {LoaderMini} from "../loader";

const CurrentlyPlaying = styled.div`
    background-color: ${Colour.Subdue};
    border-radius:5px;
    padding: 20px 20px;
`;
const CurrentSongInfo = styled.div`
    display: flex;
    justify-content: space-between;
    width: 400px;
    margin-top: 8px;
    
    > span {
        display: inline-block;
        word-wrap:break-word;
    }
`;

const CurrentSongPlaying = (props) => {
  const [nowPlaying, setNowPlaying] = React.useState(null);
  const [isPaused, setPaused] = React.useState(false);
  const [isPlaying, setPlaying] = React.useState(false);
  

  const { loading } = useQuery(GET_CURRENT_RADIO_PLAY, {
    variables: {
        gameCode: "No Code",
        playlistId: props.playlistIdParam,
    },
    onCompleted: (data) => {
      setNowPlaying({ ...data });
    },
  });


  function refPlay() {
      const audio = document.getElementById("currentPlay")
    isPaused ? audio.pause() : audio.play();
    setPaused(!isPaused)
    setPlaying(true);
    const currentSec = Math.floor(audio.currentTime / 60)
    console.log(new Date(currentSec * 1000).toISOString().substr(11, 8))
  }
  
  
  return (
    <>
        {loading ? (<LoaderMini />) : (
        <CurrentlyPlaying>
            <TextContent fontSize={20} colour={Colour.White} fontWeight="700" display="block" textAlign="left">
                Currently Playing
          </TextContent>
          <CurrentSongInfo>
            <audio id="currentPlay" style={{display:"none"}}>
              <source src={nowPlaying?.getCurrentRadioStationId?.radioUrl} type="audio/ogg"></source>
            </audio>
          </CurrentSongInfo>
            <CurrentSongInfo>
            <TextContent fontSize={14} colour={Colour.White} >{props.playlistName}</TextContent>
                <TextContent fontSize={14} colour={Colour.White} >Various Artist</TextContent>
                <TextContent fontSize={14} colour={Colour.White} >30 Sec</TextContent>
            <TextContent fontSize={14} colour={Colour.White} >MP3</TextContent>
                <TextContent fontSize={14} colour={Colour.White} >
                <i className={!isPlaying ? "fas fa-play" : isPaused ? "fas fa-pause" : "far fa-play-circle"}
                onClick={refPlay}
                              />
                </TextContent>
            </CurrentSongInfo>
        </CurrentlyPlaying>
          )}
    </>
  )
}

export default CurrentSongPlaying
