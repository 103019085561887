import React from "react";
import Button from "../../components/button";
import TextContent from "../../components/textContent";
import Colour from "../../lib/colour";
import PageTitle from "../../components/pageTitle";
import Input from "../../components/input";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../lib/notificationManager";
import Table from "../../components/table";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { GET_PLAYLIST } from "../../lib/graphQl/query";
import { createSong } from "../../lib/restService";
import Select from "../../components/select";
import PropTypes from "prop-types";

const Container = styled.div`
  display: flex;

  > div:first-child {
    flex-basis: 40%;

    > div {
      margin-bottom: 32px;
    }
  }

  > div:last-child {
    flex-basis: 60%;
    margin-left: 64px;
  }
`;

const ButtonWrapper = styled.div`
  width: calc(40% - 32px);
  margin-bottom: 32px;
`;

const Error = styled.span`
  margin-top: 4px;
  font-size: 14px;
  color: ${Colour.Error};
`;

const pageLimit = 20;
export default function NewSong(props) {
  const [title, setTitle] = React.useState("");
  const [titleError, setTitleError] = React.useState("");
  const [artist, setArtist] = React.useState("");
  const [artistError, setArtistError] = React.useState("");
  const [album, setAlbum] = React.useState("");
  const [albumError, setAlbumError] = React.useState("");
  const [referenceId, setReferenceId] = React.useState("");
  const [referenceIdError, setReferenceIdError] = React.useState("");
  const [saving, setSaving] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [offset, setOffset] = React.useState(0);
  const [file, setFile] = React.useState(null);
  const [fileError, setFileError] = React.useState("");
  const [selectedPlaylist, setSelectedPlaylist] = React.useState([]);
  const [selectedPlaylistError, setSelectedPlaylistError] = React.useState("");
  const fileInputRef = React.createRef();
  const [songFormatValue, setSongFormatValue] = React.useState({
    label: "MP3",
    value: "mp3",
  });

  const { data, loading } = useQuery(GET_PLAYLIST, {
    variables: {
      offset,
      limit: pageLimit,
    },
  });

  const columns = [{ title: "PLAYLISTS", dataIndex: "title" }];
  const songFormatOption = [
    {
      label: "MP3",
      value: "mp3",
    },
    {
      label: "WAV",
      value: "wav",
    },
    {
      label: "WMA",
      value: "wma",
    },
    {
      label: "AAC",
      value: "aac",
    },
    {
      label: "MP4",
      value: "mp4",
    },
  ];
  async function onSubmit(event) {
    event.preventDefault();

    if (saving) {
      return;
    }

    setSaving(true);
    try {
      let shouldSubmit = true;
      if (!title.trim()) {
        shouldSubmit = false;
        setTitleError("Title must be filled");
      } else if (titleError.trim()) {
        setTitleError("");
      }
      if (!artist.trim()) {
        shouldSubmit = false;
        setArtistError("Artist must be filled");
      } else if (artistError.trim()) {
        setArtistError("");
      }
      if (!album.trim()) {
        shouldSubmit = false;
        setAlbumError("Album must be filled");
      } else if (albumError.trim()) {
        setAlbumError("");
      }
      if (!referenceId.trim()) {
          shouldSubmit = false;
          setReferenceIdError("Reference ID must be filled");
      } else if (referenceIdError.trim()) {
          setReferenceIdError("")
      }
      if (!selectedPlaylist.length) {
        shouldSubmit = false;
        setSelectedPlaylistError("At least one playlist must be selected");
      } else if (selectedPlaylist.length) {
        setSelectedPlaylistError("");
      }
      if (!file) {
        shouldSubmit = false;
        setFileError("Please select a song file");
      } else if (file) {
        setFileError("");
      }

      if (shouldSubmit) {
        const formData = new FormData();
        formData.append("playlistId", selectedPlaylist.join(","));
        formData.append("intention", "song");
        formData.append("songTitle", title);
        formData.append("artistName", artist);
        formData.append("albumName", album);
        formData.append("isrCode", referenceId);
        formData.append("file", file);
        formData.append("fileExt", songFormatValue);

        const response = await createSong(formData);

        if (response.data.status === "success") {
          showSuccessNotification("Song Added Successfully");
          resetFormFields();
        }

        if (response.data.status === "failed") {
          showErrorNotification("Error Adding Song");
        }
      }
    } catch (e) {
      showErrorNotification("There is an error adding new song");
    } finally {
      setSaving(false);
    }
  }
  function resetFormFields() {
    setTitle("");
    setAlbum("");
    setReferenceId("");
    setArtist("");
    setFile(null);
  }

  function onPrevPage() {
    setOffset((page - 2) * pageLimit);
    setPage((prevPage) => prevPage - 1);
  }

  function onNextPage() {
    setOffset(page * pageLimit);
    setPage((prevPage) => prevPage + 1);
  }

  function onFileSelect(event) {
    if (event?.currentTarget?.files.length) {
      setFile(event.currentTarget.files[0]);
    }
  }

  function onSelectedRow(row) {
    setSelectedPlaylist(row);
  }

  function onInitiateFileSelect() {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }
  function onFormatChange(newSortValue) {
    setSongFormatValue({ ...newSortValue });
  }

  return (
    <div>
      <form onSubmit={onSubmit} autoComplete="on">
        <PageTitle
          title="Upload a Song"
          rightComponent={
            <Button type="submit" disabled={saving} loading={saving}>
              <TextContent fontSize={14} fontWeight="600" colour={Colour.White}>
                Save Changes
              </TextContent>
            </Button>
          }
          goBackText="Back to All Songs"
          canGoBack
        />
        <ButtonWrapper>
          <Button
            variant={file ? "success" : "subdue"}
            onClick={onInitiateFileSelect}
            fullWidth
          >
            <>
              {file ? `Upload - ${file.name}` : "Upload Song"}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={onFileSelect}
              />
            </>
          </Button>
          {fileError && <Error>{fileError}</Error>}
        </ButtonWrapper>
        <Container>
          <div>
            <Input
              label="Song Title"
              value={title}
              onChange={setTitle}
              error={titleError}
              required
            />
            <Input
              label="Artist"
              value={artist}
              onChange={setArtist}
              error={artistError}
              required
            />
            <Input
              label="Album"
              value={album}
              onChange={setAlbum}
              error={albumError}
              required
            />
            <Input
                label="Reference Number"
                value={referenceId}
                onChange={setReferenceId}
                error={referenceIdError}
                required
            />
            <Select
              label="Type"
              value={songFormatValue.value}
              options={songFormatOption}
              fullWidth
              isFormInput
              onSelect={onFormatChange}
            />
          </div>
          <div>
            {selectedPlaylistError && <Error>{selectedPlaylistError}</Error>}
            <Table
              total={data?.listPlaylist?.totalCount || 1}
              columns={columns}
              data={data?.listPlaylist?.data || []}
              onNextPage={onNextPage}
              onPrevPage={onPrevPage}
              loading={loading}
              page={page}
              limit={pageLimit}
              onSelectedRowChange={onSelectedRow}
              showPagination
              canSelectRows
            />
          </div>
        </Container>
      </form>
    </div>
  );
}

NewSong.propTypes = {
  songFormatValue: PropTypes.object,
};

NewSong.defaultProps = {
  songFormatValue: {
    label: "MP3",
    value: "mp3",
  },
};
