import { BrowserRouter } from "react-router-dom";
import AuthWrapper, { logout } from "./components/authWrapper";
import Navigation from "./components/navigation";
import React from "react";
import { setContext } from "@apollo/client/link/context";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
  from,
} from "@apollo/client";
import { getStorageData, StorageKeys } from "./lib/storageManager";
import { showErrorNotification } from "./lib/notificationManager";
import { onError } from "@apollo/client/link/error";

const errorLink = onError(
  ({ graphQLErrors, operation, forward, networkError }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err.extensions.code) {
          case "UNAUTHENTICATED":
            // Call logout here as the session has expired
            logout();
            return forward(operation);
          default:
            showErrorNotification("Operation Error");
        }
      }
    }
    if (networkError) {
      showErrorNotification("Network Error");
    }
  }
);
const httpLink = new HttpLink({ uri: process.env.REACT_APP_API_URL });
const authLink = setContext((_, { headers }) => {
  const token = getStorageData(StorageKeys.AuthToken);
  return {
    headers: {
      ...headers,
      "authorization-token": token || undefined,
    },
  };
});

const client = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
});

export default function App() {
  return (
    <ApolloProvider client={client}>
      <AuthWrapper>
        <BrowserRouter>
          <Navigation />
        </BrowserRouter>
      </AuthWrapper>
    </ApolloProvider>
  );
}
