import React from "react";
import Input from "../input";
import Button from "../button";
import Modal from "../modal";
import PropTypes from "prop-types";
import styled from "styled-components";
import Colour from "../../lib/colour";
import TextContent from "../textContent";
import PlaylistImage from "../playlistImage";

const Form = styled.form`
    > button {
        margin-top: 32px;
    }
    > div {
        margin-bottom: 24px;
    }
`;


export default function NewOrEditPlaylistModal(props) {
    const [image, setImage] = React.useState(props.image || null);
    const [file, setFile] = React.useState(null);
    const [imageError, setImageError] = React.useState("");
    const [name, setName] = React.useState(props.name || "");
    const [nameError, setNameError] = React.useState("");

    React.useEffect(() => {
        if (props.name) {
            setName(props.name);
        }
        if (props.image) {
            setImage(props.image);
        }
    }, [props.name, props.image]);

    async function onSubmit(event) {
        event.preventDefault();
        let shouldSubmit = true;

        if (!name.trim()) {
            shouldSubmit = false;
            setNameError("Name must be filled");
        } else if (nameError.trim()) {
            setNameError("")
        }
        if (props.isEdit && !image) {
            shouldSubmit = false;
            setImageError("Please upload an image");
        } else if (imageError.trim()) {
            setImageError("")
        }

        if (shouldSubmit && props.onSubmit) {
            props.onSubmit({
                id: props.id,
                title: name,
                file: file,
                image: props.image,
            });
        }
    }

    function resetFields() {
        setName("");
        setImage("");
        setFile(null);
    }

    function onClose() {
        resetFields();
        props.onClose();
    }

    function onFileChange(newImage) {
        setFile(newImage);
        setImage(URL.createObjectURL(newImage));
    }

    return (
        <Modal
            isActive={props.isActive}
            onCancel={onClose}
            title={props.isEdit ? "Edit Playlist Property" : "New Playlist"}
        >
            <Form onSubmit={onSubmit} autoComplete="on">
                {props.isEdit && <PlaylistImage image={image} isEdit={props.isEdit} onFileChange={onFileChange} />}
                {props.isEdit && imageError && <TextContent fontSize={14} colour={Colour.Error}>{imageError}</TextContent>}
                <Input
                    label="Playlist Name"
                    value={name}
                    onChange={setName}
                    error={nameError}
                    required
                />
                <Button type="submit" fullWidth disabled={props.isProcessing} loading={props.isProcessing}>
                    {props.isEdit ? "Save Changes" : "Create Playlist"}
                </Button>
            </Form>
        </Modal>
    );
}

NewOrEditPlaylistModal.propTypes = {
    isEdit: PropTypes.bool,
    isProcessing: PropTypes.bool,
    id: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string,
    isActive: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}
