import React from "react";
import Input from "../input";
import Button from "../button";
import Modal from "../modal";
import PropTypes from "prop-types";
import styled from "styled-components";
import Colour from "../../lib/colour";
import TextContent from "../textContent";

const Form = styled.form`
    > button {
        margin-top: 32px;
    }
    > div {
        margin-bottom: 24px;
    }
`;

const SongButtonWrapper = styled.div`
    margin-top: 32px;
    margin-bottom: 16px;
`;

const Error = styled.span`
    margin-top: 4px;
    font-size: 14px;
    color: ${Colour.Error};
`;

export default function NewSongModal(props) {
    const [title, setTitle] = React.useState("");
    const [titleError, setTitleError] = React.useState("");
    const [artist, setArtist] = React.useState("");
    const [artistError, setArtistError] = React.useState("");
    const [album, setAlbum] = React.useState("");
    const [albumError, setAlbumError] = React.useState("");
    const [referenceId, setReferenceId] = React.useState("");
    const [referenceIdError, setReferenceIdError] = React.useState("");
    const [file, setFile] = React.useState(null);
    const [fileError, setFileError] = React.useState("");
    const fileInputRef = React.createRef();

    React.useEffect(() => {
        if (props.editingSong?.title) {
            setTitle(props.editingSong.title);
            setArtist(props.editingSong.artist);
            setAlbum(props.editingSong.album);
            setReferenceId(props.editingSong.isrCode);
        }
        // console.log(props.editingSong);
    }, [props.editingSong]);

    async function onSubmit(event) {
        event.preventDefault();
        let shouldSubmit = true;

        if (!title.trim()) {
            shouldSubmit = false;
            setTitleError("Title must be filled");
        } else if (titleError.trim()) {
            setTitleError("")
        }
        if (!artist.trim()) {
            shouldSubmit = false;
            setArtistError("Artist must be filled");
        } else if (artistError.trim()) {
            setArtistError("")
        }
        if (!album.trim()) {
            shouldSubmit = false;
            setAlbumError("Album must be filled");
        } else if (albumError.trim()) {
            setAlbumError("")
        }
        if (!referenceId.trim()) {
            shouldSubmit = false;
            setReferenceIdError("Reference ID must be filled");
        } else if (referenceIdError.trim()) {
            setReferenceIdError("")
        }
        if (!props.isEdit && !file) {
            shouldSubmit = false;
            setFileError("Please select a song file");
        } else if (file) {
            setFileError("")
        }

        if (shouldSubmit && props.onSubmit) {
            props.onSubmit({
                id: props.editingSong?.id,
                featuredPlaylists: props.editingSong?.featuredPlaylists,
                file: file ? file : undefined,
                title,
                artist,
                album,
                isrCode: referenceId,
            });
            console.log("saved");
        }
    }

    function onFileSelect(event) {
        if (event?.currentTarget?.files.length) {
            setFile(event.currentTarget.files[0]);
        }
    }

    function onInitiateFileSelect() {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    return (
        <Modal
            isActive={props.isActive}
            onCancel={props.onClose}
            title="Upload Song"
        >
            <Form onSubmit={onSubmit} autoComplete="on">
                <SongButtonWrapper>
                    <Button
                        variant={props.isEdit || file ? "success" : "subdue"}
                        onClick={props.isEdit ? undefined : onInitiateFileSelect}
                        fullWidth
                    >
                        <>
                            <TextContent fontSize={18} colour={Colour.White}>
                                {props.isEdit
                                    ? `Uploaded - ${props.editingSong?.title}.mp3`
                                    : file
                                        ? `Upload - ${file.name}`
                                        : "Upload Song"
                                }
                            </TextContent>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                onChange={onFileSelect}
                            />
                        </>
                    </Button>
                    {fileError && <Error>{fileError}</Error> }
                </SongButtonWrapper>
                <Input
                    label="Song Title"
                    value={title}
                    onChange={setTitle}
                    error={titleError}
                    required
                />
                <Input
                    label="Artist"
                    value={artist}
                    onChange={setArtist}
                    error={artistError}
                    required
                />
                <Input
                    label="Album"
                    value={album}
                    onChange={setAlbum}
                    error={albumError}
                    required
                />
                <Input
                    label="Reference Number"
                    value={referenceId}
                    onChange={setReferenceId}
                    error={referenceIdError}
                    placeholder={referenceId ? referenceId : "N/A"}
                    required
                />
                <Button type="submit" fullWidth loading={props.isProcessing} disabled={props.isProcessing}>
                    <TextContent fontSize={18} colour={Colour.White}>Save Changes</TextContent>
                </Button>
            </Form>
        </Modal>
    );
}

NewSongModal.propTypes = {
    isActive: PropTypes.bool.isRequired,
    isEdit: PropTypes.bool,
    isProcessing: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    editingSong: PropTypes.object,
}
