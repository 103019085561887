import React from "react";
import Colour from "../../lib/colour";
import TextContent from "../textContent";
import PropTypes from "prop-types";
import styled from "styled-components";

const Status = styled.span`
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 21px;
    background-color: ${props => props.colour};
`;

const StatusIndicator = styled.span`
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px;
    background-color: ${props => props.colour};
`;

function getVariantColour(variant) {
    const variants = {
        success: {
            backgroundColor: Colour.SuccessFade,
            colour:     Colour.Success,
        },
        error: {
            backgroundColor: Colour.ErrorFade,
            colour:     Colour.Error,
        },
    };

    return variants[variant];
}

export default function Tag(props) {
    const variantColor = getVariantColour(props.variant);

    return (
        <Status colour={variantColor.backgroundColor}>
            <StatusIndicator colour={variantColor.colour}/>
            <TextContent fontSize={12} fontWeight="500" color={Colour.TextGrey}>
                {props.tagLabel}
            </TextContent>
        </Status>
    )
}

Tag.propTypes = {
    variant: PropTypes.oneOf(["success", "error"]),
    tagLabel: PropTypes.string.isRequired,
}

Tag.defaultProps = {
    variant: "success",
}
