import { ExportToCsv } from "export-to-csv";

const options = {
    fieldSeparator:   ",",
    decimalSeparator: ".",
    showLabels:       true,
    showTitle:        false,
    useTextFile:      false,
    useBom:           true,
    useKeysAsHeaders: true,
};

export default function downloadAsCSV(data, name) {
    const csvExporter = new ExportToCsv({
        ...options,
        filename: name,
    });

    csvExporter.generateCsv(data);
}
