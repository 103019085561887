import React from "react";
import TextContent from "../../components/textContent";
import Tab from "../../components/tab";
import styled from "styled-components";
import Colour from "../../lib/colour";
import Button from "../../components/button";
import { Link } from "react-router-dom";
import { routes } from "../../components/navigation/routes";
import Table from "../../components/table";
import PageTitle from "../../components/pageTitle";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_PLATFORM_ANALYTICS,
  GET_TOP_USERS,
  GET_TRENDING_PLAYLIST,
} from "../../lib/graphQl/query";
// import { DateTime } from "luxon";
import { authContext } from "../../components/authWrapper";
import Tag from "../../components/tag";
import { RADIO_REBOOT } from "../../lib/graphQl/mutation";
import { showErrorNotification, showSuccessNotification } from "../../lib/notificationManager";

const TabItems = [
  { name: "Today", key: "TODAY" },
  { name: "This Week", key: "THIS_WEEK" },
  { name: "This Month", key: "THIS_MONTH" },
  { name: "All Time", key: "ALL_TIME" },
];

const AnalyticsCard = styled.div`
  border-radius: 5px;
  padding: 48px;
  background-color: ${(props) => props.colour};
  flex: 1;
`;

const AnalyticsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    margin-right: 16px;

    :last-child {
      margin-right: none;
    }
  }
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 32px 0px;
`;

const PlaylistImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > img {
    margin-right: 0px;

    :last-child {
      margin-right: none;
    }
  }
`;

const PlaylistImage = styled.img`
  border-radius: 10px;
  height: 140px;
  width: 180px;
`;

export default function Dashboard() {
  const authContextProps = React.useContext(authContext);
  const [activeTab, setActiveTab] = React.useState("ALL_TIME");
  const columns = [
    {
      title: "USERS",
      dataIndex: "fullname",
      render: (text) => (
        <TextContent fontSize={14} fontWeight="500" colour={Colour.BlackText}>
          {text}
        </TextContent>
      ),
    },
    { title: "EMAIL ADDRESSES", dataIndex: "email" },
    {
      title: "TYPE",
      dataIndex: "userExperience",
      sorter: (a, b) => a.userExperience.length - b.userExperience.length,
      align: "center",
      render: (text) => (
        <TextContent fontSize={14} colour={Colour.BlackText}>
          {text.join(", ")}
        </TextContent>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "accountActive",
      sorter: (a, b) => a.accountActive && b.accountActive,
      align: "center",
      render: (isActive) => (
        <Tag
          tagLabel={isActive ? "Active" : "Inactive"}
          variant={isActive ? "success" : "error"}
        />
      ),
    },
    { title: "GAMES PLAYED", dataIndex: "totalGamesPlayed", align: "center" },
    // {
    //   title: "CREATED",
    //   dataIndex: "dateRegistered",
    //   render: (time) => (
    //     <TextContent fontSize={14} colour={Colour.BlackText}>
    //       {DateTime.fromISO(time).toFormat("dd-MM-y")}
    //     </TextContent>
    //   ),
    // },
  ];

  const { data, loading } = useQuery(GET_PLATFORM_ANALYTICS, {
    variables: { period: activeTab },
  });
  const { data: topUsersData, loading: topUsersLoading } = useQuery(
    GET_TOP_USERS,
    { variables: { limit: 10 } }
  );
  const { data: trendingPlaylistData, loading: trendingPlaylistLoading } =
    useQuery(GET_TRENDING_PLAYLIST, { variables: { limit: 5 } });
  
  // reboot radio
  const [rebootTheRadio] = useMutation(RADIO_REBOOT);
  async function radioRebootOnClick() {
    const resp = await rebootTheRadio();
    if (resp?.data?.adminRadioColdReboot?.status === "success") {
      showSuccessNotification(resp.data.adminRadioColdReboot.message);
    }

    if (resp?.data?.adminRadioColdReboot?.status === "failed") {
        showErrorNotification(resp.data.adminRadioColdReboot.message);
    }
  }

  // TODO: pass in live data to this function
  function renderTabData() {
    return (
      <>
        <AnalyticsWrapper>
          <AnalyticsCard colour={Colour.PrimaryDeep}>
            <TextContent
              textAlign="center"
              display="block"
              fontSize={32}
              fontWeight="700"
              colour={Colour.White}
            >
              {loading
                ? "Loading"
                : data?.adminGetPlatformAnalytics?.data?.totalUsers}
            </TextContent>
            <TextContent
              textAlign="center"
              fontSize={14}
              colour={Colour.White}
              display="block"
            >
              Total&nbsp;Users
            </TextContent>
          </AnalyticsCard>
          <AnalyticsCard colour={Colour.Primary}>
            <TextContent
              textAlign="center"
              display="block"
              fontSize={32}
              fontWeight="700"
              colour={Colour.White}
            >
              {loading
                ? "Loading"
                : data?.adminGetPlatformAnalytics?.data?.totalGamesPlayed}
            </TextContent>
            <TextContent
              textAlign="center"
              fontSize={14}
              colour={Colour.White}
              display="block"
            >
              Games
            </TextContent>
          </AnalyticsCard>
          <AnalyticsCard colour={Colour.GreyLight}>
            <TextContent
              textAlign="center"
              display="block"
              fontSize={32}
              fontWeight="700"
              colour={Colour.White}
            >
              {loading
                ? "Loading"
                : data?.adminGetPlatformAnalytics?.data?.totalHosts}
            </TextContent>
            <TextContent
              textAlign="center"
              fontSize={14}
              colour={Colour.White}
              display="block"
            >
              Hosts
            </TextContent>
          </AnalyticsCard>
          <AnalyticsCard colour={Colour.Black}>
            <TextContent
              textAlign="center"
              display="block"
              fontSize={32}
              fontWeight="700"
              colour={Colour.White}
            >
              {loading
                ? "Loading"
                : data?.adminGetPlatformAnalytics?.data?.totalPlayer}
            </TextContent>
            <TextContent
              textAlign="center"
              fontSize={14}
              colour={Colour.White}
              display="block"
            >
              Players
            </TextContent>
          </AnalyticsCard>
        </AnalyticsWrapper>
        <SectionTitle>
          <TextContent fontSize={24} fontWeight="700" colour={Colour.Primary}>
            Top Users
          </TextContent>
          <Link to={routes.users}>
            <Button variant="black">
              <TextContent fontSize={14} colour={Colour.White}>
                All Users
              </TextContent>
            </Button>
          </Link>
        </SectionTitle>
        <Table
          columns={columns}
          data={topUsersData?.adminGetTopPlayers?.data || []}
          loading={topUsersLoading}
        />
        {(trendingPlaylistData?.getTrendingPlaylist?.data.length ||
          trendingPlaylistLoading) && (
          <SectionTitle>
            <TextContent fontSize={24} fontWeight="700" colour={Colour.Primary}>
              Trending Playlists
            </TextContent>
            <Link to={routes.playlist}>
              <Button variant="black">
                <TextContent fontSize={14} colour={Colour.White}>
                  All Playlists
                </TextContent>
              </Button>
            </Link>
          </SectionTitle>
        )}
        {trendingPlaylistLoading && (
          <TextContent fontSize={24} colour={Colour.Primary} fontWeight="bold">
            Loading
          </TextContent>
        )}
        {trendingPlaylistData?.getTrendingPlaylist?.data && (
          <PlaylistImageWrapper>
            {trendingPlaylistData?.getTrendingPlaylist?.data.map((playlist) => (
              <PlaylistImage
                key={playlist.title}
                src={playlist.imageUrl}
                alt={playlist.title}
              />
            ))}
          </PlaylistImageWrapper>
        )}
      </>
    );
  }

  return (
    <div>
      <PageTitle
        title={`Hello ${authContextProps.user?.fullname}`}
        rightComponent={(
          <Button onClick={radioRebootOnClick}>
              <TextContent fontSize={14} fontWeight="600" colour={Colour.White}>
                  Radio Reboot
              </TextContent>
          </Button>
        )}
      />
      <Tab activeTab={activeTab} items={TabItems} onTabSelect={setActiveTab} />
      {renderTabData()}
    </div>
  );
}
