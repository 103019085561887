import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/button';
import { routes } from '../../components/navigation/routes';
import PageTitle from '../../components/pageTitle';
import SongTable from '../../components/songTable';
import Tab from '../../components/tab';
import TextContent from '../../components/textContent';
import Colour from '../../lib/colour';
import { DELETE_SONG } from '../../lib/graphQl/mutation';
import { GET_SONGS } from '../../lib/graphQl/query';
import { showErrorNotification, showSuccessNotification } from '../../lib/notificationManager';

const TabItems = [
  { name: 'All Time', key: null },
  { name: 'Today', key: 'TODAY' },
  { name: 'This Week', key: 'THISWEEK' },
  { name: 'This Month', key: 'THISMONTH' },
];

const pageLimit = 20;
export default function Songs() {
  const [activeTab, setActiveTab] = React.useState(null);
  const [searchQuery, setSearchQuery] = React.useState(null);
  const [sortValue, setSortValue] = React.useState({
    label: 'Date-Descending',
    value: 'date_descending',
    sort: 'DESCENDING',
    type: 'DATE',
  });
  const [sortPlayValue, setSortPlayValue] = React.useState({
    label: 'Most Played',
    value: 'most_played',
    sort: 'DESCENDING',
    type: 'NUMBER',
  });
  const [page, setPage] = React.useState(1);
  const [offset, setOffset] = React.useState(0);

  const { data, loading, error } = useQuery(GET_SONGS, {
    variables: {
      offset,
      limit: pageLimit,
      filterBy: activeTab,
      nameLike: searchQuery,
      sortType: sortValue.type,
      sortOrder: sortValue.sort,
      // period: activeTab,
    },
  });

  const [deleteSong] = useMutation(DELETE_SONG, {
    update: (cache, { data: { adminDeleteSong } }) => {
      if (adminDeleteSong.deletedTrackId) {
        cache.modify({
          fields: {
            listSongs(existingListSong, { readField }) {
              return {
                totalCount: existingListSong.totalCount - 1,
                data: existingListSong.data.filter(
                  (songRef) => readField('id', songRef) !== adminDeleteSong.deletedTrackId
                ),
              };
            },
          },
        });
      }
    },
  });

  React.useEffect(() => {
    if (error) {
      showErrorNotification(error);
    }
  }, [error]);

  function onDeleteSong(songId) {
    return async () => {
      const resp = await deleteSong({
        variables: { id: songId },
      });

      if (resp?.data?.adminDeleteSong?.status === 'success') {
        showSuccessNotification(resp.data.adminDeleteSong.message);
      }

      if (resp?.data?.adminDeleteSong?.status === 'failed') {
        showErrorNotification(resp.data.adminDeleteSong.message);
      }
    };
  }

  function onPrevPage() {
    setOffset((page - 2) * pageLimit);
    setPage((prevPage) => prevPage - 1);
  }

  function onSortChange(newSortValue) {
    setSortValue({ ...newSortValue });
    setSortPlayValue({ ...newSortValue });
  }

  function onNextPage() {
    setOffset(page * pageLimit);
    setPage((prevPage) => prevPage + 1);
  }

  return (
    <div>
      <PageTitle
        title="Songs"
        rightComponent={
          <Link to={routes.newSong}>
            <Button>
              <TextContent fontSize={14} fontWeight="600" colour={Colour.White}>
                New Song
              </TextContent>
            </Button>
          </Link>
        }
      />
      <SongTable
        data={data?.listSongs?.data || []}
        total={data?.listSongs?.totalCount || 1}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        onDelete={onDeleteSong}
        loading={loading}
        page={page}
        limit={pageLimit}
        onSearch={setSearchQuery}
        searchValue={searchQuery}
        sortValue={sortValue}
        sortPlayValue={sortPlayValue}
        onSortSelect={onSortChange}
        onSortPlaySelect={onSortChange}
        canSort
        canSortPlayCount
      >
        <Tab activeTab={activeTab} items={TabItems} onTabSelect={setActiveTab} />
      </SongTable>
    </div>
  );
}
