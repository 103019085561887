import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation loginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      status
      message
      token
      data {
        id
        fullname
        email
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      status
      message
    }
  }
`;

export const DEACTIVATE_ADMIN = gql`
  mutation adminDeactivateUser($id: ID!) {
    adminDeactivateUser(id: $id) {
      status
      message
      data {
        id
        fullname
        email
        dateRegistered
        accountActive
      }
    }
  }
`;

export const ACTIVATE_ADMIN = gql`
  mutation adminActivateUser($id: ID!) {
    adminActivateUser(id: $id) {
      status
      message
      data {
        id
        fullname
        email
        dateRegistered
        accountActive
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateProfile($fullname: String!, $username: String!) {
    updateProfile(fullname: $fullname, username: $username) {
      status
      message
      data {
        id
        fullname
        email
        dateRegistered
        accountActive
      }
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($currentPassword: String!, $newPassword: String!) {
    updatePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
      status
      message
    }
  }
`;

export const UPDATE_ADMIN = gql`
  mutation adminEditStaff($id: ID!, $fullname: String!, $email: String!) {
    adminEditStaff(id: $id, fullname: $fullname, email: $email) {
      success
      message
      data {
        id
        fullname
        email
        dateRegistered
        accountActive
      }
    }
  }
`;

export const CREATE_ADMIN = gql`
  mutation adminCreateNewStaff($fullname: String!, $email: String!, $password: String!) {
    adminCreateNewStaff(fullname: $fullname, email: $email, password: $password) {
      status
      message
      data {
        id
        fullname
        email
        dateRegistered
        accountActive
      }
    }
  }
`;

export const DELETE_SONG = gql`
  mutation adminDeleteSong($id: ID!) {
    adminDeleteSong(id: $id) {
      status
      message
      deletedTrackId
    }
  }
`;

export const DELETE_PLAYLIST = gql`
  mutation adminDeletePlaylist($id: ID!) {
    adminDeletePlaylist(id: $id) {
      status
      message
      deletedPlaylistId
    }
  }
`;

export const CREATE_PLAYLIST = gql`
  mutation adminCreatePlaylist($title: String!, $description: String!) {
    adminCreatePlaylist(title: $title, description: $description) {
      status
      message
      data {
        id
        title
        totalTracks
        totalPlays
        tags
        imageUrl
        createdAt
      }
    }
  }
`;

export const EDIT_PLAYLIST = gql`
  mutation adminEditPlaylist($id: ID!, $title: String!, $description: String!, $imageUrl: String!) {
    adminEditPlaylist(id: $id, title: $title, description: $description, imageUrl: $imageUrl) {
      status
      message
      data {
        id
        title
        totalTracks
        totalPlays
        tags
        imageUrl
        createdAt
      }
    }
  }
`;

export const EDIT_SONG = gql`
  mutation adminEditSong(
    $id: ID!
    $isrCode: String!
    $title: String!
    $artist: String!
    $album: String!
    $featuredPlaylists: [String]!
  ) {
    adminEditSong(
      id: $id
      isrCode: $isrCode
      title: $title
      artist: $artist
      album: $album
      featuredPlaylists: $featuredPlaylists
    ) {
      status
      message
      data {
        id
        title
        featuredPlaylists
        isrCode
        totalPlays
        artist
        createdAt
        trackUrl
        featuredOnRadio
      }
    }
  }
`;

export const CREATE_BUNDLE = gql`
  mutation adminCreateBundle(
    $title: String!
    $maxGameParticipant: Int!
    $defaultMaxPlaylist: Int!
    $costOfAcquisition: Float!
    $subscriptionType: SubscriptionTypeEnum!
    $discount: Int
  ) {
    adminCreateBundle(
      title: $title
      maxGameParticipant: $maxGameParticipant
      defaultMaxPlaylist: $defaultMaxPlaylist
      costOfAcquisition: $costOfAcquisition
      subscriptionType: $subscriptionType
      discount: $discount
    ) {
      status
      message
      data {
        id
        title
        maxGameParticipant
        defaultMaxPlaylist
        costOfAcquisition
        subscriptionType
        discount
      }
    }
  }
`;

export const DELETE_BUNDLE = gql`
  mutation adminDeleteBundle($id: ID!) {
    adminDeleteBundle(id: $id) {
      status
      message
      deletedBundleId
    }
  }
`;

export const UPDATE_BUNDLE = gql`
  mutation adminEditBundle(
    $id: ID!
    $title: String!
    $maxGameParticipant: Int!
    $defaultMaxPlaylist: Int!
    $costOfAcquisition: Float!
    $subscriptionType: SubscriptionTypeEnum!
    $discount: Int
  ) {
    adminEditBundle(
      id: $id
      title: $title
      maxGameParticipant: $maxGameParticipant
      defaultMaxPlaylist: $defaultMaxPlaylist
      costOfAcquisition: $costOfAcquisition
      subscriptionType: $subscriptionType
      discount: $discount
    ) {
      status
      message
      data {
        id
        title
        defaultMaxPlaylist
        maxGameParticipant
        costOfAcquisition
        subscriptionType
        discount
      }
    }
  }
`;

export const FEATURE_SONG_TOGGLE = gql`
  mutation adminToggleTrackRadioFeature($id: ID!) {
    adminToggleTrackRadioFeature(id: $id) {
      status
      message
      data {
        id
        title
        featuredPlaylists
        totalPlays
        artist
        createdAt
        trackUrl
        featuredOnRadio
      }
    }
  }
`;

export const RADIO_REBOOT = gql`
  mutation {
    adminRadioColdReboot {
      status
      message
    }
  }
`;

export const CREATE_TRIAL_CAMPAIGN = gql`
  mutation adminCreateTrialCampaign(
    $title: String!
    $startDate: String!
    $endDate: String!
    $noOfTrialDays: Int!
    $campaingClass: CampaignClassEnum!
    $campaignType: CampaignTypeEnum!
    $noOfTrialGames: Int!
    $selectablePlaylistIds: [ID!]
  ) {
    adminCreateTrialCampaign(
      title: $title
      startDate: $startDate
      endDate: $endDate
      noOfTrialDays: $noOfTrialDays
      campaingClass: $campaingClass
      campaignType: $campaignType
      noOfTrialGames: $noOfTrialGames
      selectablePlaylistIds: $selectablePlaylistIds
    ) {
      status
      message
      data {
        id
        title
        startDate
        endDate
        campaingClass
        noOfTrialDays
        noOfTrialGames
        campaignType
        selectablePlaylistIds
        active
      }
    }
  }
`;

export const UPDATE_TRIAL_CAMPAIGN = gql`
  mutation adminUpdateTrialCampaign($id: ID!) {
    adminUpdateTrialCampaign(id: $id) {
      status
      message
      data {
        id
        title
        startDate
        endDate
        campaingClass
        noOfTrialDays
        active
      }
    }
  }
`;
