import React from "react";
import TextContent from "../../components/textContent";
import Colour from "../../lib/colour";
import PageTitle from "../../components/pageTitle";
import Button from "../../components/button";
import TableTools from "../../components/tableTools";
import Table from "../../components/table";
import Tab from "../../components/tab";
import { generateParamUrl, routes } from "../../components/navigation/routes";
import styled from "styled-components";
import {Link} from "react-router-dom";
import Tag from "../../components/tag";
import NewOrEditPlaylistModal from "../../components/newOrEditPlaylistModal";
import {gql, useMutation, useQuery} from "@apollo/client";
import { GET_PLAYLIST } from "../../lib/graphQl/query";
import {DateTime} from "luxon";
import {dateComparator} from "../../lib/sort";
import {CREATE_PLAYLIST, DELETE_PLAYLIST, EDIT_PLAYLIST} from "../../lib/graphQl/mutation";
import {showErrorNotification, showSuccessNotification} from "../../lib/notificationManager";
import {updatePlaylistBanner} from "../../lib/restService";
import downloadAsCSV from "../../lib/exportCSV";

const TabItems = [
    { name: "All Time", key: null },
    { name: "Played Today", key: "TODAY" },
    { name: "This Week", key: "THISWEEK" },
    { name: "This Month", key: "THISMONTH" },
]

const IconWrapper = styled.div`
    > span {
        font-size: 20px;
        color: ${Colour.DeepGrey};
        cursor: pointer;
        padding: 8px;
        
        :first-child {
            margin-right: 16px;
        }
    }
`;

const Title = styled.span`
    font-size: 16px;
    color: ${Colour.DeepGrey};
`;

const pageLimit = 20;
export default function Playlist() {
    const [activeTab, setActiveTab] = React.useState(null);
    const [isModalActive, setModalActive] = React.useState(false);
    const [isProcessing, setProcessing] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [offset, setOffset] = React.useState(0);
    const [editingPlaylist, setEditingPlaylist] = React.useState(null);
    const [searchQuery, setSearchQuery] = React.useState(null);
    const [sortValue, setSortValue] = React.useState({
        label: "Date-Descending",
        value: "date_descending",
        sort: "DESCENDING",
        type: "DATE",
    });

    const { data, loading } = useQuery(GET_PLAYLIST, {
        variables: {
            offset,
            limit: pageLimit,
            filterBy: activeTab,
            nameLike: searchQuery,
            sortType: sortValue.type,
            sortOrder: sortValue.sort,
        }
    });
    const [editPlaylist] = useMutation(EDIT_PLAYLIST);
    const [deletePlaylist] = useMutation(DELETE_PLAYLIST, {
        update:  (cache, { data: { adminDeletePlaylist } }) => {
            if (adminDeletePlaylist.deletedPlaylistId) {
                cache.modify({
                    fields: {
                        listPlaylist(existingListPlaylist, { readField }) {
                            return {
                                totalCount: existingListPlaylist.totalCount - 1,
                                data: existingListPlaylist.data.filter(
                                    playlistRef => readField("id", playlistRef) !== adminDeletePlaylist.deletedPlaylistId
                                ),
                            }
                        }
                    }
                });
            }
        }
    });
    const [createPlaylist] = useMutation(CREATE_PLAYLIST, {
        update:  (cache, { data: { adminCreatePlaylist } }) => {
            cache.modify({
                fields: {
                    listPlaylist(existingListPlaylist) {
                        const newListPLayListRef = cache.writeFragment({
                            data: adminCreatePlaylist.data,
                            fragment: gql`
                                fragment NewPlayList on Playlist {
                                    id
                                    title
                                    totalTracks
                                    totalPlays
                                    tags
                                    createdAt
                                }
                            `
                        });
                        return {
                            totalCount: existingListPlaylist.totalCount + 1,
                            data: [...existingListPlaylist.data, newListPLayListRef],
                        }
                    }
                }
            });
        }
    });

    const columns = [
        {
            title: "PLAYLIST",
            dataIndex: "title",
            render: (text, record) =>(
                <Link to={generateParamUrl(routes.viewPlaylist, record.id)}>
                    <TextContent fontSize={14} fontWeight="500" colour={Colour.BlackText}>
                        {text}
                    </TextContent>
                </Link>
            ),
        },
        { title: "SONGS", dataIndex: "totalTracks" },
        { title: "PLAYED", dataIndex: "totalPlays" },
        {
            title: "TAGS",
            dataIndex: "tags",
            render: tags => tags.length
                ? tags.map(tag => <Tag tagLabel={tag} variant="success" key={tag} />)
                : <TextContent fontSize={14} colour={Colour.BlackText}>----</TextContent>
        },
        {
            title: "CREATED",
            dataIndex: "createdAt",
            sorter: (a, b) => dateComparator(a.createdAt, b.createdAt),
            render: time => (
                <TextContent fontSize={14} colour={Colour.BlackText}>
                    {DateTime.fromISO(time).toFormat("dd-MM-y")}
                </TextContent>
            ),
        },
        {
            title: (
                <Title>
                    <i className="fas fa-trash-alt" />
                </Title>
            ),
            align: "center",
            render: record => (
                <IconWrapper>
                    <span onClick={onDeletePlaylist(record.id)}>
                        <i className="fas fa-trash-alt" />
                    </span>
                    <span onClick={onEditPlaylist(record)}>
                        <i className="far fa-edit" />
                    </span>
                </IconWrapper>
            )
        },
    ];

    function onDeletePlaylist(playlistId) {
        return async () => {
            const resp = await deletePlaylist({
                variables: { id: playlistId }
            });

            if (resp?.data?.adminDeletePlaylist?.status === "success") {
                showSuccessNotification(resp.data.adminDeletePlaylist.message);
            }

            if (resp?.data?.adminDeletePlaylist?.status === "failed") {
                showErrorNotification(resp.data.adminDeletePlaylist.message);
            }
        }
    }

    function onEditPlaylist(record) {
        return () => {
            if (!isProcessing) {
                setEditingPlaylist(record);
                setModalActive(true);
            }
        }
    }

    async function onSubmit(createPlaylistData) {
        setProcessing(true);
        const resp = await createPlaylist({
            variables: {
                title: createPlaylistData.title,
                description: `${createPlaylistData.title}-description`,
            }
        })

        if (resp?.data?.adminCreatePlaylist?.status === "success") {
            showSuccessNotification(resp.data.adminCreatePlaylist.message);
            onCloseModal();
        }

        if (resp?.data?.adminCreatePlaylist?.status === "failed") {
            showErrorNotification(resp.data.adminCreatePlaylist.message);
        }
        setProcessing(false);
    }

    async function onEditSubmit(editPlaylistData) {
        try {
            setProcessing(true);
            let response = {
                data: {
                    link: editPlaylistData.image,
                    status: "success",
                }
            };

            if (editPlaylistData.file) {
                const formData = new FormData();
                formData.append("playlistId", editPlaylistData.id);
                formData.append("intention", "playlist");
                formData.append("file", editPlaylistData.file);

                response = await updatePlaylistBanner(formData);
            }

            if (response.data?.status === "success") {
                const resp = await editPlaylist({
                    variables: {
                        id: editPlaylistData.id,
                        title: editPlaylistData.title,
                        description: `${editPlaylistData.title}-description`,
                        imageUrl: response.data.link,
                    }
                })

                if (resp?.data?.adminEditPlaylist?.status === "success") {
                    showSuccessNotification(resp.data.adminEditPlaylist.message);
                    onCloseModal();
                }

                if (resp?.data?.adminEditPlaylist?.status === "failed") {
                    showErrorNotification(resp.data.adminEditPlaylist.message);
                }
            }

            if (response.data?.status === "failed") {
                showErrorNotification(response.data.message);
            }
        } catch (e) {
            showErrorNotification("There is an error updating playlist");
        } finally {
            setProcessing(false);
        }
    }

    function onPrevPage() {
        setOffset((page - 2) * pageLimit);
        setPage(prevPage => prevPage - 1);
    }

    function onNextPage() {
        setOffset(page * pageLimit);
        setPage(prevPage => prevPage + 1);
    }

    function onNewPlaylist() {
        setModalActive(true);
    }

    function onCloseModal() {
        setModalActive(false);
        setEditingPlaylist(null);
    }

    function onDownload() {
        if (data?.listPlaylist?.data) {
            downloadAsCSV(data.listPlaylist.data.map(playlist => ({
                title: playlist.title,
                totalTracks: playlist.totalTracks,
                tags: playlist.tags.join(", "),
                totalPlays: playlist.totalPlays,
            })), "PLaylists")
        }
    }

    function onSortChange(newSortValue) {
        setSortValue({...newSortValue});
    }

    return (
        <div>
            <PageTitle
                title="Playlists"
                rightComponent={(
                    <Button onClick={onNewPlaylist} disabled={isProcessing}>
                        <TextContent fontSize={14} fontWeight="600" colour={Colour.White}>New Playlist</TextContent>
                    </Button>
                )}
            />
            <TableTools
                onSearch={setSearchQuery}
                onDownload={onDownload}
                searchPlaceholder="Search playlists"
                searchValue={searchQuery}
                sortValue={sortValue}
                onSortSelect={onSortChange}
                canSearch
                canDownload
                canSort
            />
            <Tab activeTab={activeTab} items={TabItems} onTabSelect={setActiveTab} />
            <Table
                total={data?.listPlaylist?.totalCount || 1}
                columns={columns}
                data={data?.listPlaylist?.data || []}
                onNextPage={onNextPage}
                onPrevPage={onPrevPage}
                loading={loading}
                page={page}
                limit={pageLimit}
                showPagination
                canSelectRows
            />
            <NewOrEditPlaylistModal
                isEdit={!!editingPlaylist}
                isActive={isModalActive}
                editingPlaylist={editingPlaylist}
                id={editingPlaylist?.id}
                name={editingPlaylist?.title}
                image={editingPlaylist?.imageUrl}
                onClose={onCloseModal}
                onSubmit={!!editingPlaylist ? onEditSubmit : onSubmit}
                isProcessing={isProcessing}
            />
        </div>
    );
}
