import { useMutation } from '@apollo/client';
import { Howl, Howler } from 'howler';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Colour from '../../lib/colour';
import downloadAsCSV from '../../lib/exportCSV';
import { EDIT_SONG } from '../../lib/graphQl/mutation';
import { showErrorNotification, showSuccessNotification } from '../../lib/notificationManager';
import { dateComparator } from '../../lib/sort';
import NewSongModal from '../newSongModal';
import Table from '../table';
import TableTools from '../tableTools';
import TextContent from '../textContent';

const IconWrapper = styled.div`
  cursor: pointer;

  > span {
    font-size: 20px;
    color: ${(props) => (props.isActive ? Colour.Success : Colour.DeepGrey)};
    cursor: pointer;
    padding: 8px;
  }
`;
const CustomCheckBox = styled.div`
  position: relative;
  display: flex;
  padding: 8px;
  font-size: 25px;
  justify-content: center;
  > input {
    visibility: hidden;
    cursor: pointer;

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      ${'' /* content: '☆'; */}
      content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='20' height='20' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M16.8925 7.81178C17.0503 7.66199 17.1622 7.47043 17.2153 7.25944C17.2684 7.04846 17.2604 6.82673 17.1923 6.62011C17.1235 6.41157 16.9974 6.22656 16.8285 6.0862C16.6597 5.94584 16.4547 5.85578 16.2371 5.82628L12.2423 5.25428C12.1589 5.2426 12.0795 5.21091 12.0111 5.1619C11.9426 5.11289 11.887 5.04803 11.849 4.97286L10.0615 1.3997C9.96161 1.20426 9.80966 1.04022 9.62243 0.925666C9.4352 0.81111 9.21998 0.750488 9.00048 0.750488C8.78099 0.750488 8.56576 0.81111 8.37853 0.925666C8.19131 1.04022 8.03936 1.20426 7.93944 1.3997L6.15286 4.96828C6.1149 5.04345 6.0593 5.10831 5.99082 5.15732C5.92233 5.20632 5.84301 5.23802 5.75961 5.2497L1.76477 5.8217C1.54716 5.85119 1.34222 5.94126 1.17334 6.08162C1.00445 6.22198 0.878413 6.40699 0.809608 6.61553C0.741484 6.82215 0.733491 7.04387 0.786563 7.25486C0.839634 7.46585 0.951582 7.6574 1.10936 7.8072L3.99961 10.5847C4.06043 10.6428 4.10597 10.7151 4.13219 10.795C4.15842 10.875 4.16451 10.9602 4.14994 11.043L3.46794 14.9654C3.43709 15.1324 3.44422 15.3041 3.48882 15.4678C3.53341 15.6316 3.61431 15.7832 3.72552 15.9114C3.90225 16.1145 4.14176 16.2527 4.406 16.304C4.67025 16.3553 4.94406 16.3168 5.18394 16.1947L8.75894 14.343C8.83445 14.3051 8.9178 14.2853 9.00232 14.2853C9.08683 14.2853 9.17018 14.3051 9.24569 14.343L12.8207 16.1947C13.0599 16.3196 13.3345 16.3596 13.5994 16.3082C13.8644 16.2567 14.104 16.1168 14.2791 15.9114C14.3903 15.7832 14.4712 15.6316 14.5158 15.4678C14.5604 15.3041 14.5675 15.1324 14.5367 14.9654L13.8547 11.043C13.84 10.9602 13.8461 10.875 13.8723 10.795C13.8985 10.715 13.9441 10.6428 14.005 10.5847L16.8925 7.81178Z' fill='%23C4C4C4'/%3e%3c/svg%3e ");
      color: ${Colour.Grey};
      visibility: visible;
    }

    &:checked:before {
      ${'' /* content: '★'; */}
      content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='20' height='20' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M16.8925 7.81178C17.0503 7.66199 17.1622 7.47043 17.2153 7.25944C17.2684 7.04846 17.2604 6.82673 17.1923 6.62011C17.1235 6.41157 16.9974 6.22656 16.8285 6.0862C16.6597 5.94584 16.4547 5.85578 16.2371 5.82628L12.2423 5.25428C12.1589 5.2426 12.0795 5.21091 12.0111 5.1619C11.9426 5.11289 11.887 5.04803 11.849 4.97286L10.0615 1.3997C9.96161 1.20426 9.80966 1.04022 9.62243 0.925666C9.4352 0.81111 9.21998 0.750488 9.00048 0.750488C8.78099 0.750488 8.56576 0.81111 8.37853 0.925666C8.19131 1.04022 8.03936 1.20426 7.93944 1.3997L6.15286 4.96828C6.1149 5.04345 6.0593 5.10831 5.99082 5.15732C5.92233 5.20632 5.84301 5.23802 5.75961 5.2497L1.76477 5.8217C1.54716 5.85119 1.34222 5.94126 1.17334 6.08162C1.00445 6.22198 0.878413 6.40699 0.809608 6.61553C0.741484 6.82215 0.733491 7.04387 0.786563 7.25486C0.839634 7.46585 0.951582 7.6574 1.10936 7.8072L3.99961 10.5847C4.06043 10.6428 4.10597 10.7151 4.13219 10.795C4.15842 10.875 4.16451 10.9602 4.14994 11.043L3.46794 14.9654C3.43709 15.1324 3.44422 15.3041 3.48882 15.4678C3.53341 15.6316 3.61431 15.7832 3.72552 15.9114C3.90225 16.1145 4.14176 16.2527 4.406 16.304C4.67025 16.3553 4.94406 16.3168 5.18394 16.1947L8.75894 14.343C8.83445 14.3051 8.9178 14.2853 9.00232 14.2853C9.08683 14.2853 9.17018 14.3051 9.24569 14.343L12.8207 16.1947C13.0599 16.3196 13.3345 16.3596 13.5994 16.3082C13.8644 16.2567 14.104 16.1168 14.2791 15.9114C14.3903 15.7832 14.4712 15.6316 14.5158 15.4678C14.5604 15.3041 14.5675 15.1324 14.5367 14.9654L13.8547 11.043C13.84 10.9602 13.8461 10.875 13.8723 10.795C13.8985 10.715 13.9441 10.6428 14.005 10.5847L16.8925 7.81178Z' fill='%23FFD559'/%3e%3c/svg%3e");
      color: ${Colour.Gold};
    }
  }
`;
const MiniIconWrap = styled.div`
  display: flex;
`;

export default function SongTable(props) {
  const howler = React.useRef(null);
  const [editingSong, setEditingSong] = React.useState(null);
  const [isModalActive, setModalActive] = React.useState(false);
  const [isPaused, setPaused] = React.useState(false);
  const [isProcessing, setProcessing] = React.useState(false);
  const [playingSongId, setPlayingSongId] = React.useState(null);

  const [editSong] = useMutation(EDIT_SONG);

  React.useEffect(() => {
    return () => {
      Howler.stop();
      Howler.unload();
    };
  }, []);
  const columns = [
    {
      title: 'SONGS',
      dataIndex: 'title',
      render: (text, record) => (
        <TextContent fontSize={14} fontWeight="500" colour={Colour.BlackText} onClick={onEditSong(record)}>
          {text}
        </TextContent>
      ),
    },
    {
      title: 'PLAYLISTS',
      dataIndex: 'featuredPlaylists',
      align: 'center',
      render: (featuredPlaylists) => (
        <TextContent fontSize={14} colour={Colour.BlackText}>
          {featuredPlaylists.length}
        </TextContent>
      ),
    },
    { title: 'ARTIST', dataIndex: 'artist', align: 'center' },
    {
      title: 'PLAYED',
      dataIndex: 'totalPlays',
      align: 'center',
      render: (totalPlays) => (
        <TextContent fontSize={14} colour={Colour.BlackText}>
          {totalPlays}
        </TextContent>
      ),
    },
    {
      title: 'DURATION',
      dataIndex: 'trackLength',
      align: 'center',
      render: (trackLength) => (
        <TextContent fontSize={14} colour={Colour.BlackText}>
          {!!trackLength ? `${trackLength}s` : 'N/A'}
        </TextContent>
      ),
    },
    {
      title: 'CREATED',
      dataIndex: 'createdAt',
      align: 'center',
      sorter: (a, b) => dateComparator(a.createdAt, b.createdAt),
      render: (time) => (
        <TextContent fontSize={14} colour={Colour.BlackText}>
          {DateTime.fromISO(time).toFormat('dd-MM-y')}
        </TextContent>
      ),
    },
    {
      title: '',
      dataIndex: 'id',
      align: 'center',
      render: (songId, record) => (
        <IconWrapper onClick={onPlayPause(record.trackUrl, songId)} isActive={isPaused && songId === playingSongId}>
          <span>
            <i
              className={songId === playingSongId ? (isPaused ? 'far fa-play-circle' : 'fas fa-pause') : 'fas fa-play'}
            />
          </span>
        </IconWrapper>
      ),
    },
    {
      title: '',
      dataIndex: 'id',
      align: 'center',
      render: (songId, record) => {
        return (
          <MiniIconWrap>
            {props.featureIcon && (
              <IconWrapper>
                <CustomCheckBox>
                  <input
                    className="star-check"
                    type="checkbox"
                    onChange={(e) => props.handleCheckChange(record, e)}
                    name={songId}
                    defaultValue={record.featuredOnRadio}
                    defaultChecked={record.featuredOnRadio}
                  />
                </CustomCheckBox>
              </IconWrapper>
            )}

            <IconWrapper onClick={props.onDelete(songId)}>
              <span>
                <i className="fas fa-trash-alt" />
              </span>
            </IconWrapper>
          </MiniIconWrap>
        );
      },
    },
  ];
  function onPlayPause(songTrack, songId) {
    return () => {
      try {
        if (!playingSongId || playingSongId !== songId) {
          setPaused(false);
          Howler.stop();
          howler.current = new Howl({
            src: [songTrack],
            autoplay: true,
            html5: true,
            onend: function () {
              setPlayingSongId(null);
              setPaused(false);
              howler.current = null;
            },
            onloaderror: function () {
              showErrorNotification('There is an error loading the song');
            },
            onplayerror: function () {
              showErrorNotification('There is an error playing the song');
            },
            onload: function () {
              setPlayingSongId(songId);
            },
          });
        } else {
          if (howler.current?.playing([howler.current])) {
            howler.current?.pause();
            setPaused(true);
          } else {
            howler.current?.play();
            setPaused(false);
          }
        }
      } catch (e) {
        showErrorNotification('There is an on song operations');
      }
    };
  }

  function onDownload() {
    if (props.data) {
      downloadAsCSV(
        props.data.map((song) => ({
          referenceId: song.isrCode,
          title: song.title,
          artist: song.artist,
          album: song.album,
          songUrl: song.trackUrl,
          totalPlays: song.totalPlays,
        })),
        'Songs'
      );
    }
  }

  function onCloseModal() {
    setModalActive(false);
    setEditingSong(null);
  }

  async function onEditSubmit(submitData) {
    setProcessing(true);
    const resp = await editSong({ variables: submitData });

    if (resp?.data?.adminEditSong?.status === 'success') {
      onCloseModal();
      showSuccessNotification(resp.data.adminEditSong.message);
    }

    if (resp?.data?.adminEditSong?.status === 'failed') {
      showErrorNotification(resp.data.adminEditSong.message);
    }
    setProcessing(false);
  }

  function onEditSong(record) {
    return () => {
      if (!isProcessing) {
        setEditingSong(record);
        setModalActive(true);
      }
    };
  }

  return (
    <>
      {!props.hideTableTools && (
        <TableTools
          onSearch={props.onSearch}
          onDownload={onDownload}
          sortValue={props.sortValue}
          sortPlayValue={props.sortPlayValue}
          searchPlaceholder="Search song title"
          searchValue={props.searchValue}
          canSearch={!props.hideSearch}
          onSortSelect={props.onSortSelect}
          onSortPlaySelect={props.onSortPlaySelect}
          canSort={props.canSort}
          canSortPlayCount={props.canSortPlayCount}
          canDownload
        />
      )}
      {props.children}
      <Table
        columns={columns}
        data={props.data}
        total={props.total}
        onNextPage={props.onNextPage}
        onPrevPage={props.onPrevPage}
        loading={props.loading}
        page={props.page}
        limit={props.limit}
        showPagination
        canSelectRows
      />
      <NewSongModal
        isActive={isModalActive}
        onClose={onCloseModal}
        onSubmit={onEditSubmit}
        editingSong={editingSong}
        isProcessing={isProcessing}
        isEdit
      />
    </>
  );
}

SongTable.propTypes = {
  children: PropTypes.element,
  onNextPage: PropTypes.func,
  onPrevPage: PropTypes.func,
  loading: PropTypes.bool,
  hideTableTools: PropTypes.bool,
  hideSearch: PropTypes.bool,
  page: PropTypes.number,
  limit: PropTypes.number,
  total: PropTypes.number,
  data: PropTypes.array,
  onSearch: PropTypes.func,
  onDelete: PropTypes.func,
};

SongTable.defaultProps = {
  children: null,
};
