import React from "react";
import Table from "../../components/table";
import TextContent from "../../components/textContent";
import Colour from "../../lib/colour";
import PageTitle from "../../components/pageTitle";
import TableTools from "../../components/tableTools";
import { useMutation, useQuery,gql } from "@apollo/client";
import { GET_BUNDLES } from "../../lib/graphQl/query";
import { CREATE_BUNDLE, DELETE_BUNDLE, UPDATE_BUNDLE } from "../../lib/graphQl/mutation";
import Button from "../../components/button";
import styled from "styled-components";
import { showErrorNotification, showSuccessNotification } from "../../lib/notificationManager";
import Modal from "../../components/modal";
import Input from "../../components/input";
import Select from "../../components/select";


const IconWrapper = styled.div`
    > span {
        font-size: 20px;
        color: ${Colour.DeepGrey};
        cursor: pointer;
        padding: 8px;
        
        :first-child {
            margin-right: 16px;
        }
    }
`;
const Title = styled.span`
    font-size: 16px;
    color: ${Colour.DeepGrey};
`;

const Form = styled.form`
    > button {
        margin-top: 32px;
    }
    > div {
        margin-bottom: 24px;
    }
`;

const pageLimit = 5;
export default function Bundles() {
  const [editBundleId, setEditBundleId] = React.useState("");
  const [bundleName, setBundleName] = React.useState("");
  const [bundleNameError, setBundleNameError] = React.useState("");
  const [bundlePrice, setBundlePrice] = React.useState("");
  const [bundlePriceError, setBundlePriceError] = React.useState("");
  const [gamePlayers, setGamePlayers] = React.useState("");
  const [gamePlayersError, setGamePlayersError] = React.useState("");
  const [discountPrice, setDiscountPrice] = React.useState("");
  const [discountPriceError, setDiscountPriceError] = React.useState("");
  const [playlistCount, setPlaylistCount] = React.useState("");
  const [playlistCountError, setPlaylistCountError] = React.useState("");
  const [saving, setSaving] = React.useState(false);
  const [isNewBundleModalOpen, setIsNewBundleModalOpen] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [editingBundle, setEditingBundle] = React.useState({});
  const [page, setPage] = React.useState(1);
  const [offset, setOffset] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState(null);   
  const [bundleFuncValue, setBundleFuncValue] = React.useState({
    label: "One Time",
    value: "ONE_TIME",
  });
  const [sortValue, setSortValue] = React.useState({
    label: "Date-Descending",
    value: "date_descending",
    sort: "DESCENDING",
    type: "DATE",
  });

  const bundleFuncOption = [
    {
      label: "One Time",
      value: "ONE_TIME",
    },
    {
      label: "Monthly",
      value: "MONTHLY",
    },
    {
      label: "Yearly",
      value: "YEARLY",
    },
  ];
  

  const { data, loading, error } = useQuery(GET_BUNDLES, {
    variables: {
      limit: pageLimit,
      offset: offset,
      nameLike: searchQuery,
      sortType: sortValue.type,
      sortOrder: sortValue.sort,
    }
});
console.log(data)
const [createBundle] = useMutation(CREATE_BUNDLE, {
    update:  (cache, { data: { adminCreateBundle } }) => {
        cache.modify({
            fields: {
              adminListBundle(existingBundle) {
                    const newListBundleRef = cache.writeFragment({
                        data: adminCreateBundle.data,
                        fragment: gql`
                            fragment NewBundle on Bundle {
                              id
                              title
                              defaultMaxPlaylist
                              costOfAcquisition
                              subscriptionType
                              maxGameParticipant
                              discount
                            }
                        `
                    });
                    return {
                        totalCount: existingBundle.totalCount + 1,
                        data: [...existingBundle.data, newListBundleRef],
                    }
                }
            }
        });
    },
}); 
const [deleteBundle] = useMutation(DELETE_BUNDLE, {
  update: (cache, { data: { adminDeleteBundle } }) => {
      if (adminDeleteBundle.deletedBundleId) {
          cache.modify({
              fields: {
                adminListBundle(existingListBundle, { readField }) {
                      return {
                          totalCount: existingListBundle.totalCount - 1,
                          data: existingListBundle.data.filter(
                              bundleRef => readField("id", bundleRef) !== adminDeleteBundle.deletedBundleId
                          ),
                      }
                  }
              }
          });
      }
  }
});
  const [updateAdmin] = useMutation(UPDATE_BUNDLE);

  
  async function onDeleteBundle(bundleId) {
    //  async () => {
      const resp = await deleteBundle({
        variables: { id: bundleId }
      });
      if (resp?.data?.adminDeleteBundle?.status === "success") {
        showSuccessNotification(resp.data.adminDeleteBundle.message);
      }
      
      if (resp?.data?.adminDeleteBundle?.status === "failed") {
        showErrorNotification(resp.data.adminDeleteBundle.message);
      }
    // }
  }
  
  const columns = [
    {
      title: (
        <TextContent fontSize={12} fontWeight={"500"} colour={Colour.TextGrey}>
          BUNDLES
        </TextContent>
      ),
      dataIndex: "title",
      render: (text) => (
        <TextContent fontSize={14} colour={Colour.TextGrey}>
          {text}
        </TextContent>
      ),
    // responsive: ["sm"]
    },
    { title: (
      <TextContent fontSize={12} fontWeight={"500"} colour={Colour.TextGrey}>
        PRICE
      </TextContent>
    ), dataIndex: "costOfAcquisition", align: "center",
      render: (text) => (
        <TextContent fontSize={14} colour={Colour.TextGrey}>
          {`$${text}`}
        </TextContent>
      ),
      // responsive: ["sm"]
    },
    { title: (
      <TextContent fontSize={12} fontWeight={"500"} colour={Colour.TextGrey}>
        PLAYLIST
      </TextContent>
    ), dataIndex: "defaultMaxPlaylist", align: "center",
      render: (text) => (
        <TextContent fontSize={14} colour={Colour.TextGrey}>
          {text}
        </TextContent>
      ),
      // responsive: ["sm"]
    },
    {
      title: (
        <Title>
            <i className="far fa-trash-alt" />
        </Title>
      ),
    dataIndex: "id",
    align: "center",
      render: (id, record) => {
      //   if (authContextProps.user.id === id) {
      //     return null;
      // }
        // console.log(record)
      const onEdit = () => onBundleEdit(record);
      const onDelete = () => onDeleteBundle(id);
        return  (
          <IconWrapper>
            
            <span onClick={onDelete}>
              <i className="far fa-trash-alt" />
            </span>
          <span onClick={onEdit}>
            <i className="far fa-edit" />
          </span>
          </IconWrapper>
        )
    },
    // responsive: ["sm"]
    },
  ];

  

async function onSubmit(event) {
  event.preventDefault();
  setSaving(true);
  try {
      let shouldSubmit = true;
      if (!bundleName.trim()) {
          shouldSubmit = false;
        setBundleNameError("Bundle Name must be filled");
      } else if (bundleNameError.trim()) {
          setBundleNameError("");
      }
      if (!bundlePrice) {
          shouldSubmit = false;
          setBundlePriceError("Bundle Price must be filled");
      } else if (bundlePriceError.trim()) {
          setBundlePriceError("");
      }
      if (!gamePlayers) {
          shouldSubmit = false;
          setGamePlayersError("Game Players (max) must be filled");
      } else if (gamePlayersError.trim()) {
          setGamePlayersError("");
      }
      if (!playlistCount) {
          shouldSubmit = false;
          setPlaylistCountError("Playlist Count must be filled");
      } else if (playlistCountError.trim()) {
          setPlaylistCountError("");
      }
    if (isEditing && shouldSubmit) {
          const resp = await updateAdmin({
              variables: {
                  id: editBundleId,
                  title: bundleName,
                  maxGameParticipant: parseInt(gamePlayers),
                  costOfAcquisition: parseFloat(bundlePrice),
                  defaultMaxPlaylist: parseInt(playlistCount),
                  subscriptionType: bundleFuncValue.value,
                  discount: parseInt(discountPrice),
              }
          });
          processResponse(resp.data?.adminEditBundle);
      } else if (shouldSubmit) {
          const resp = await createBundle({
              variables: {
                title: bundleName,
                maxGameParticipant: parseInt(gamePlayers),
                costOfAcquisition: parseFloat(bundlePrice),
                defaultMaxPlaylist: parseInt(playlistCount),
                subscriptionType: bundleFuncValue.value,
                discount: parseInt(discountPrice),
              }
          })
          processResponse(resp.data?.adminCreateBundle);
      }

  }
  catch (e) {
      showErrorNotification(`There is an error ${isEditing ? "editing" : "adding new"} bundle`);
      
    } finally {
      setSaving(false);
    }
}


  function onPrevPage() {
    setOffset((page - 2) * pageLimit);
    setPage((prevPage) => prevPage - 1);
  }

  function onNextPage() {
    setOffset(page * pageLimit);
    setPage((prevPage) => prevPage + 1);
  }
  
  function processResponse(response) {
    if (response?.status === "success") {
        onClose();
        showSuccessNotification(response.message);
    }

    if (response?.status === "failed") {
        showErrorNotification(response.message);
    }
}

function resetFields() {
    setEditingBundle(null);
    setIsEditing(false);
    setBundleName("");
    setBundlePrice("");
    setGamePlayers("");
    setPlaylistCount("");
    setDiscountPrice("");
    setBundleFuncValue({
    label: "One Time",
    value: "ONE_TIME",
  });
}


function toggleBundleModal() {
    setIsNewBundleModalOpen(!isNewBundleModalOpen);
}

function onClose() {
    resetFields();
    toggleBundleModal();
}
  function onBundleEdit(aparam) {
    // console.log(aparam);
    setEditingBundle(aparam);
    setEditBundleId(aparam.id)
    setBundleName(aparam.title);
    setBundlePrice(aparam.costOfAcquisition);
    setPlaylistCount(aparam.defaultMaxPlaylist);
    // setBundleFuncValue(...aparam.subscriptionType);
    setGamePlayers(aparam.maxGameParticipant);
    setDiscountPrice(aparam.discount);
    setIsEditing(true);
    toggleBundleModal();
}

function onFormatChange(newSortValue) {
  setBundleFuncValue({ ...newSortValue });
}
function onSortChange(newSortValue) {
    setSortValue({...newSortValue});
}
  
    return (
        <div>
        <PageTitle title="Bundles"
          rightComponent={(
                <Button onClick={toggleBundleModal}>
                    <TextContent fontSize={14} fontWeight="600" colour={Colour.White}>New Bundle</TextContent>
                </Button>
            )}
        />
      <TableTools
        onSearch={setSearchQuery}
        searchPlaceholder="Search Bundles"
        searchValue={searchQuery}
        sortValue={sortValue}
        onSortSelect={onSortChange}
        canSearch
        canSort
      />
      <Table
        columns={columns}
        data={data?.adminListBundle?.data || []}
        loading={loading}
        page={page}
        limit={pageLimit}
        total={data?.adminListBundle?.totalCount || 1}
        showPagination
        onPrevPage={onPrevPage}
        onNextPage={onNextPage}
        canSelectRows
        />
        <Modal
            isActive={isNewBundleModalOpen}
            onCancel={onClose}
            title={isEditing ? "Edit Bundle Property" : "New Bundle"}
        >
            <Form onSubmit={onSubmit} autoComplete="on">
                <Input
                    label="Bundle Name"
                    value={bundleName}
                    onChange={setBundleName}
                    error={bundleNameError}
                    required
                    name="bundle_name"
                />
                <Input
                    type="number"
                    label="Price"
                    value={bundlePrice.toString()}
                    onChange={setBundlePrice}
                    error={bundlePriceError}
                    required
                    minVal="0"
                    step="0.01"
                    name="bundle_price"
                />
                <Input
                    type="number"
                    label="Game Participant (max)"
                    value={gamePlayers.toString()}
                    onChange={setGamePlayers}
                    error={gamePlayersError}
                    required
                    minVal="1"
                    step="0.01"
                    name="game_players"
                />
                <Input
                    type="number"
                    label="Playlist Count"
                    value={playlistCount.toString()}
                    onChange={setPlaylistCount}
                    error={playlistCountError}
                    required
                    minVal="1"
                    step="0.01"
                    name="playlist_count"
                />
                <Select
                    label="Payment Frequency"
                    value={bundleFuncValue.value}
                    options={bundleFuncOption}
                    fullWidth
                    isFormInput
                    onSelect={onFormatChange}
                    name="payment_frequency"
                />
                <Input
                    type="number"
                    label="Discount"
                    value={discountPrice.toString()}
                    onChange={setDiscountPrice}
                    error={discountPriceError}
                    minVal="0"
                    step="0.01"
                    name="discount_value"
                />
                <Button type="submit" fullWidth disabled={saving} loading={saving}>
                    {isEditing ? "Save Changes" : "Create Bundle"}
                </Button>
            </Form>
        </Modal>
    </div>
    )
}
